<template>
    <div id="steps">
        <div class="steps-headers">
            <div @click="changeStep('principal')" v-bind:class="{'step-box' : true, 'active': steps.principal}">
                <template v-if="!$screenSize.isMobileOrSmallTablet.value">
                    {{ $t('sector3SistemaVoltaStep1Principal') }}
                </template>
                <template v-else>
                    {{ $t('sector3SistemaVolta') }}
                </template>
            </div>
            <div @click="changeStep('stepOne')" v-bind:class="{'step-box' : true, 'active': steps.stepOne}">
                <template v-if="!$screenSize.isMobileOrSmallTablet.value">
                {{ $t('sector3SistemaVoltaStep1One') }}
                </template>
                <template v-else>
                    <template v-if="steps.stepOne">
                        {{ $t('sector3SistemaVoltaStep1One') }}
                    </template>
                    <template v-else>
                        01
                    </template>
                </template>
            </div>
            <div @click="changeStep('stepTwo')" v-bind:class="{'step-box' : true, 'active': steps.stepTwo}">
                <template v-if="!$screenSize.isMobileOrSmallTablet.value">
                    {{ $t('sector3SistemaVoltaStep1Two') }}
                </template>
                <template v-else>
                    <template v-if="steps.stepTwo">
                        {{ $t('sector3SistemaVoltaStep1Two') }}
                    </template>
                    <template v-else>
                        02
                    </template>
                </template>
                <img v-if="bloquedStep(2)" src="./assets/bloqued.png" alt="">
            </div>
            <div @click="changeStep('stepThree')" v-bind:class="{'step-box' : true, 'active': steps.stepThree}">
                <template v-if="!$screenSize.isMobileOrSmallTablet.value">
                    {{ $t('sector3SistemaVoltaStep1Three') }}
                </template>
                <template v-else>
                    <template v-if="steps.stepThree">
                        {{ $t('sector3SistemaVoltaStep1Three') }}
                    </template>
                    <template v-else>
                        03
                    </template>
                </template>
                <img v-if="bloquedStep(3)" src="./assets/bloqued.png" alt="">
            </div>
            <div @click="changeStep('stepFour')" v-bind:class="{'step-box' : true, 'active': steps.stepFour}">
                <template v-if="!$screenSize.isMobileOrSmallTablet.value">
                    {{ $t('sector3SistemaVoltaStep1Four') }}
                </template>
                <template v-else>
                    <template v-if="steps.stepFour">
                        {{ $t('sector3SistemaVoltaStep1Four') }}
                    </template>
                    <template v-else>
                        04
                    </template>
                </template>
                <img v-if="bloquedStep(4)" src="./assets/bloqued.png" alt="">
            </div>
        </div>
        <!-- <div v-if="$screenSize.isMobileOrSmallTablet.value" class="steps-headers-mobile">
            <div>{{ $t('sector3SistemaVolta') }}</div>
        </div> -->
        <div class="steps-body">
            <step-principal v-if="steps.principal"></step-principal>
            <step-one v-if="steps.stepOne"></step-one>
            <step-two v-if="steps.stepTwo"></step-two>
            <step-three v-if="steps.stepThree"></step-three>
            <step-four v-if="steps.stepFour"></step-four>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    import StepPrincipal from './mini-components/step-principal';
    import StepOne from './mini-components/step-one';
    import StepTwo from './mini-components/step-two';
    import StepThree from './mini-components/step-three';
    import StepFour from './mini-components/step-four';

    export default {
        name: "steps",
        components: {
            StepPrincipal,
            StepOne,
            StepTwo,
            StepThree,
            StepFour
        },
        data() {
            return {
                steps:  {
                    principal: true,
                    stepOne: false,
                    stepTwo: false,
                    stepThree: false,
                    stepFour: false
                }
            }
        },
        computed: {
            ...mapGetters(['getCurrentState'])
        },
        methods: {
            changeStep: function (step) {
                let state = this.getCurrentState();

                this.steps.principal = step === 'principal';
                this.steps.stepOne = step === 'stepOne';
                this.steps.stepTwo = (step === 'stepTwo' && (state.phase.id > 1));
                this.steps.stepThree = (step === 'stepThree' && (state.phase.id > 2));
                this.steps.stepFour = (step === 'stepFour' && (state.phase.id > 3));

                console.log(this.steps.stepTwo);
            },
            bloquedStep: function (step) {
                let state = this.getCurrentState();

                if(step === 2) return !(state.phase.id > 1);
                if(step === 3) return !(state.phase.id > 2);
                if(step === 4) return !(state.phase.id > 3);
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/_variables.scss";
    #steps {
        width: 1000px;
        @include tablet {
            width: 100%;
        }
        @include mobile {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .steps-headers {
            display: flex;
            align-items: center;
            @include tablet {
                justify-content: center;
            }
            color: #fff;
            .step-box {
                position: relative;
                background-color: transparent;
                padding: 15px 50px 15px 15px;
                border: 1px solid $primary-blue-color;
                border-radius: 10px;
                margin-right: 15px;
                @include tablet {
                    padding: 2px 8px;
                }
                color: #fff;
                -webkit-transition: 0.3s;
                transition: 0.3s;
                &:hover {
                    background-color: rgba($primary-blue-color,0.3);
                }
                &.active {
                    background-color: $primary-blue-color;
                    color: #fff;
                }
               img {
                   position: absolute;
                   top: 10px;
                   right: 10px;
                    @include tablet {
                            top: -10px;
                            right: -10px;
                            width: 20px;
                        }
               }
            }
        }
        .steps-headers-mobile {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            color: #fff;
        }
    }
</style>
