import { ref } from "@vue/composition-api";
import { useEventListener } from "./eventListener";

export function useMedia(query) {
  const mediaQuery = window.matchMedia(query);
  const matches = ref(mediaQuery.matches);
  useEventListener(mediaQuery, "change", (event) => {
    matches.value = event.matches;
  });
  return matches;
}
