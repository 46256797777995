<template>
    <div class="card">
        <div class="header-card">
            <div class="header-card-icon">
                <slot name="header-icon"> </slot>
            </div>
            <slot name="header"> </slot>
        </div>
        <div class="body-card">
            <slot name="body"> </slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "card"
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    .card {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .header-card {
            color: #fff;
            padding: 20px 45px;
            @include tablet {
                padding: 8px 25px;
            }

            @include gradient(90deg,$list-purple);
            display: flex;
            align-items: center;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            * {
                font-size: 1.57rem;
                    @include tablet {
                        font-size: 1.30rem;
                    }
                line-height: 1.88rem;
                margin: 0;
            }
            .header-card-icon {
                img {
                    width: 50px;
                    height: auto;
                }
            }
        }
        .body-card {
            min-height: 400px;
            width: calc(100% - 50px);
            height: calc(100% - 50px);
            padding: 25px;
            @include tablet {
                min-height: 200px;
                width: calc(100% - 30px);
                padding: 15px;
            }

            background-color: rgba($primary-color, 0.7);
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            p {
                color: #fff;
                font-size: 15px;
                line-height: 20px;
            }
        }
    }
</style>
