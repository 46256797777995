import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'

const instance = axios.create({
    withCredentials: false
});

const querystring = require('querystring');

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loading: false,
        pausedGame: false,
        closedGame: false,
        isSocket: true,
        qrCode: '',
        username: '',
        time: {
            min: 59,
            sec: 59
        },
        generalState: {
            time: {
                min: 59,
                sec: 59
            },
            sectorOne: {
                sector: {
                    id: 1, complete: false, current: true,
                },
                phase: {
                    id: 1, complete: false, current: true
                }
            },
            sectorTwo: {
                sector: null,
                phase: null
            },
            sectorThree: {
                sector: null,
                phase: null
            },
            sectorFour: {
                sector: null,
                phase: null
            },
            sectorFive: {
                sector: null,
                phase: null
            },
        },
        currentState: {
            sector: {
                id: 1, complete: false, current: true,
            },
            phase: {
                id: 1, complete: false, current: true
            }
        }
    },
    getters: {
        getIsLoading: (state) => () => {
            return state.loading;
        },
        getClosedGame: (state) => () => {
            return state.closedGame;
        },
        getCurrentState: (state) => () => {
           return state.currentState;
        },
        getPausedGame: (state) => () => {
            return state.pausedGame;
        },
        getTime: (state) => () => {
            return state.time;
        },
        getUsername: (state) => () => {
            return state.username;
        },
        getIsSocket: (state) => () => {
            return state.isSocket;
        }
    },
    mutations: {
        updateIsLoading: (state,payload) => {
            console.log('Updating loading', state, payload);
            state.loading = payload.isLoading;
            console.log('Updated loading', state.loading);
        },
        updateIsSocket: (state,payload) => {
            state.isSocket = payload.isSocket;
        },
        updatePausedGame: (state,payload) => {
            state.pausedGame = payload.pausedGame;
        },
        updateClosedGame: (state, payload) => {
            state.closedGame = payload.closedGame;
        },
        updateGeneralState: (state,payload) => {
            state.generalState = payload;
        },
        updateUsername: (state,payload) => {
            state.username = payload.username
        },
        updateCurrentSector: (state, payload) => {
            console.log('Updating current sector', state, payload);

            state.currentState.sector.id = payload.id;
            state.currentState.sector.complete = payload.complete;
            state.currentState.sector.current = payload.current;

            let sectorObject = {id: payload.id, complete: payload.complete, current: payload.current };

            if(payload.id === 1) state.generalState.sectorOne.sector = sectorObject;
            else if(payload.id === 2) state.generalState.sectorTwo.sector = sectorObject;
            else if(payload.id === 3) state.generalState.sectorThree.sector = sectorObject;
            else if(payload.id === 4) state.generalState.sectorFour.sector = sectorObject;
            else if(payload.id === 5) state.generalState.sectorFive.sector = sectorObject;
        },
        updateCurrentPhase: (state, payload) => {
            state.currentState.phase.id = payload.id;
            state.currentState.phase.complete = payload.complete;
            state.currentState.phase.current = payload.current;

            let sectorObject = {id: payload.id, complete: payload.complete, current: payload.current };

            if(state.currentState.sector.id === 1) state.generalState.sectorOne.phase = sectorObject;
            else if(state.currentState.sector.id === 2) state.generalState.sectorTwo.phase = sectorObject;
            else if(state.currentState.sector.id === 3) state.generalState.sectorThree.phase = sectorObject;
            else if(state.currentState.sector.id === 4) state.generalState.sectorFour.phase = sectorObject;
            else if(state.currentState.sector.id === 5) state.generalState.sectorFive.phase = sectorObject;
        },
        updateTime: (state, payload) => {
            state.time.min = payload.min;
            state.time.sec = payload.sec;

            state.generalState.time.min = payload.min;
            state.generalState.time.sec = payload.sec;
        },
        updateQRCode: (state, payload) => {
            state.qrCode = payload.qrCode;
        },
        updateUserdata: (state) => {
            console.log('updateUserdata', state);
                instance.post('https://conectadosalfuturo.com/endpoints/save_escape.php',
                    querystring.stringify({
                        qrcode: state.qrCode,
                        datos: JSON.stringify(state.generalState)
                    }),
                    {
                        headers: {
                            "Access-Control-Allow-Origin" : "*",
                            "Content-Type": "application/x-www-form-urlencoded"
                        }
                    }).then((response) => {
                    console.log(response)
                })
                    .catch((error) => {
                        console.log(error);
                    });
        }
    },
})
