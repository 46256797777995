<template>
    <div>
        <div v-if="$screenSize.isMobileOrSmallTablet.value" class="mobile-modal-title">
            <p>{{ $t('generadorVanderGraff') }}</p>
        </div>
        <div id="van-de-graff">
        <div v-if="!puzzleResolved" class="puzzle-container">
            <draggable
                v-if="!$screenSize.isMobileOrSmallTablet.value && !$screenSize.isSmallMobile.value" 
                group="vandegraff"
                v-model="images"
                @start="isDragging = false"
                @end="isDragging = false"
                :move="startDrag"
                @drag.prevent
                @dragover.prevent
                :key="images.length"
            >
                <img :key="'image' + index"
                    :src="require(`${image.url}`)" :id="image.key" v-for="(image, index) in images">
            </draggable>
            <draggable
                v-if="$screenSize.isMobileOrSmallTablet.value && !$screenSize.isSmallMobile.value"
                group="vandegraff" v-model="mobileImages" @start="isDragging = false" @end="isDragging = false"
                :move="startDrag" @drag.prevent @dragover.prevent
                :key="mobileImages.length"    
            >
                <img  v-for="(image, index) in mobileImages"
                    :key="'image' + index" :src="require(`${image.url}`)" :id="image.key">
            </draggable>
            <draggable
                v-if="$screenSize.isSmallMobile.value" 
                group="vandegraff" v-model="smallMobileImages" @start="isDragging = false" @end="isDragging = false"
                :move="startDrag" @drag.prevent @dragover.prevent
                :key="smallMobileImages.length"
            >
                <img :key="'image' + index"
                    :src="require(`${image.url}`)" :id="image.key" v-for="(image, index) in smallMobileImages">
            </draggable>
        </div>
        <div v-else class="puzzle-resolved">
            <img :src="require(`./assets/grifo_1.png`)" alt="">
            <p class="title">{{ $t('generadorVanderGraffResuelto1') }}</p>
            <p>{{ $t('generadorVanderGraffResuelto2') }}</p>
        </div>
        <div  class="puzzle-result">
            <draggable group="vandegraff"
                :options="{disabled: puzzleResolved}"
                class="puzzle-result-image"
                :list="results"
                :move="moveResults"
                :key="results.length"
            >
                <div :id="'result-' + result.result" :key="'result-' + result.result" v-for="result in results"
                    class="puzzle-result-drop-zone">
                    <img v-if="result.url" :src="require(`${result.url}`)" alt="">
                </div>
            </draggable>
            <div v-bind:class="{'puzzle-result-letter' : true, 'puzzle-result-letter-finish' : puzzleResolved}"
                class="puzzle-result-letter">
                <span v-if="result.letter !== ' '" v-bind:class="{'has-letter' : result.letter !== ''}"
                    v-for="result in results">{{ result.letter }}</span>
            </div>
        </div>
    </div>
 </div>
    
</template>

<script>
    import draggable from 'vuedraggable'
    import { mapMutations } from 'vuex'
    import { mapGetters } from 'vuex'
    import { useScreenSize } from '@/composables/screenSize';

    export default {
        name: "van-de-graff-generator",
        data: () => ({
                isMobileOrSmallTablet: useScreenSize().isMobileOrSmallTablet.value,
                isSmallMobile: useScreenSize().isSmallMobile.value,
                innerHeight: useScreenSize().innerHeight,
                images: [
                    {url: './assets/puzzle-van-de-graff-1.png', key: 'puzzle-1', order: 1, result: 1, letter: 'E', locked: false},
                    {url: './assets/puzzle-van-de-graff-2.png', key: 'puzzle-2', order: 2, result: 2, letter: 'L', locked: false},
                    {url: './assets/puzzle-van-de-graff-3.png', key: 'puzzle-3', order: 3, result: 3, letter: 'E', locked: false},
                    {url: './assets/puzzle-van-de-graff-4.png', key: 'puzzle-4', order: 4, result: 4, letter: 'K', locked: false},
                    {url: './assets/puzzle-van-de-graff-5.png', key: 'puzzle-5', order: 5, result: 5, letter: 'T', locked: false},
                    {url: './assets/puzzle-van-de-graff-6.png', key: 'puzzle-6', order: 6, result: 6, letter: 'R', locked: false},
                    {url: './assets/puzzle-van-de-graff-7.png', key: 'puzzle-7', order: 7, result: 7, letter: 'O', locked: false},
                    {url: './assets/puzzle-van-de-graff-8.png', key: 'puzzle-8', order: 8, result: 8, letter: 'N', locked: false},
                    {url: './assets/puzzle-van-de-graff-9.png', key: 'puzzle-9', order: 9, result: 9, letter: ' ', locked: false},
                ],
                smallMobileImages: [
                    {url: './assets/small-mobile/puzzle-van-de-graff-1.png', key: 'puzzle-1', order: 1, result: 1, letter: 'E', locked: false},
                    {url: './assets/small-mobile/puzzle-van-de-graff-9.png', key: 'puzzle-9', order: 9, result: 9, letter: ' ', locked: false},
                    {url: './assets/small-mobile/puzzle-van-de-graff-3.png', key: 'puzzle-3', order: 3, result: 3, letter: 'E', locked: false},
                    {url: './assets/small-mobile/puzzle-van-de-graff-4.png', key: 'puzzle-4', order: 4, result: 4, letter: 'K', locked: false},
                    {url: './assets/small-mobile/puzzle-van-de-graff-2.png', key: 'puzzle-2', order: 2, result: 2, letter: 'L', locked: false},
                    {url: './assets/small-mobile/puzzle-van-de-graff-6.png', key: 'puzzle-6', order: 6, result: 6, letter: 'R', locked: false},
                    {url: './assets/small-mobile/puzzle-van-de-graff-7.png', key: 'puzzle-7', order: 7, result: 7, letter: 'O', locked: false},
                    {url: './assets/small-mobile/puzzle-van-de-graff-8.png', key: 'puzzle-8', order: 8, result: 8, letter: 'N', locked: false},
                    {url: './assets/small-mobile/puzzle-van-de-graff-5.png', key: 'puzzle-5', order: 5, result: 5, letter: 'T', locked: false},
                ],
                mobileImages: [
                    {url: './assets/mobile/puzzle-van-de-graff-1.png', key: 'puzzle-1', order: 1, result: 1, letter: 'E', locked: false},
                    {url: './assets/mobile/puzzle-van-de-graff-9.png', key: 'puzzle-9', order: 9, result: 9, letter: ' ', locked: false},
                    {url: './assets/mobile/puzzle-van-de-graff-3.png', key: 'puzzle-3', order: 3, result: 3, letter: 'E', locked: false},
                    {url: './assets/mobile/puzzle-van-de-graff-4.png', key: 'puzzle-4', order: 4, result: 4, letter: 'K', locked: false},
                    {url: './assets/mobile/puzzle-van-de-graff-2.png', key: 'puzzle-2', order: 2, result: 2, letter: 'L', locked: false},
                    {url: './assets/mobile/puzzle-van-de-graff-6.png', key: 'puzzle-6', order: 6, result: 6, letter: 'R', locked: false},
                    {url: './assets/mobile/puzzle-van-de-graff-7.png', key: 'puzzle-7', order: 7, result: 7, letter: 'O', locked: false},
                    {url: './assets/mobile/puzzle-van-de-graff-8.png', key: 'puzzle-8', order: 8, result: 8, letter: 'N', locked: false},
                    {url: './assets/mobile/puzzle-van-de-graff-5.png', key: 'puzzle-5', order: 5, result: 5, letter: 'T', locked: false},
                ],
                results: [
                    {result: 1, url: null, order: null, letter: '', locked: false},
                    {result: 2, url: null, order: null, letter: '', locked: false},
                    {result: 3, url: null, order: null, letter: '', locked: false},
                    {result: 4, url: null, order: null, letter: '', locked: false},
                    {result: 5, url: null, order: null, letter: '', locked: false},
                    {result: 6, url: null, order: null, letter: '', locked: false},
                    {result: 7, url: null, order: null, letter: '', locked: false},
                    {result: 8, url: null, order: null, letter: '', locked: false},
                    {result: 9, url: null, order: null, letter: '', locked: false},
                ],
                puzzleResolved: false
        }),
        components: {
            draggable,
        },
        created() {
            this.checkPhaseStatus();
            this.imageReOrder()
        },
        computed: {
            ...mapGetters(['getCurrentState']),
            isMobileOrSmallTabletReactive() {
                return useScreenSize().isMobileOrSmallTablet.value;
            },
            isSmallMobileReactive() {
                return useScreenSize().isSmallMobile.value;
            }
        },
        methods: {
            heightPrint() {
                console.log(this.innerHeight.value);
                setTimeout(() => {
                    this.heightPrint();
                }, 1000);
            },
            ...mapMutations(['updateCurrentPhase','updateUserdata']),
            checkPhaseStatus : function() {
                let state = this.getCurrentState();
                let solved = state.sector.id > 1 || state.phase.id > 1;
                if(solved) {
                    this.puzzleResolved = solved;
                    this.solvedPuzzle();
                }
                else this.imageReOrder();
            },
            imageReOrder : function() {
                if (this.isSmallMobileReactive) {
                    return this.smallMobileImages;
                }
                if (this.isMobileOrSmallTabletReactive) {
                    return this.mobileImages;
                }

                return this.images;
            },

            solvedPuzzle : function(){
                if (!this.isMobileOrSmallTabletReactive && !this.isSmallMobileReactive) {
                    this.images.forEach((image, index) => {
                        this.results[index].url = image.url;
                        this.results[index].order = image.order;
                        this.results[index].letter = image.letter;
                    })
                }

                if (this.isMobileOrSmallTabletReactive && !this.isSmallMobileReactive) {
                    this.mobileImages.sort((a, b) => a.order - b.order);
                    this.mobileImages.forEach((image, index) => {
                        this.results[index].url = image.url;
                        this.results[index].order = image.order;
                        this.results[index].letter = image.letter;
                    });
                }

                if (this.isSmallMobileReactive) {
                    this.smallMobileImages.sort((a, b) => a.order - b.order);
                    this.smallMobileImages.forEach((image, index) => {
                        this.results[index].url = image.url;
                        this.results[index].order = image.order;
                        this.results[index].letter = image.letter;
                    });
                }
            },
            moveResults: function ({ relatedContext, draggedContext }) {
                let draggedElement = draggedContext.element;
                let droppedElement = relatedContext.element;
                if(draggedElement.locked) {
                    return false;
                }

                if(droppedElement.locked) {
                    return false;
                }

                return true;
            },
            startDrag : function ({ relatedContext, draggedContext }) {
                let draggedElement = draggedContext.element;
                let droppedElement = relatedContext.element;

                if(draggedElement.order === droppedElement.result) {
                    let dragOrder = draggedElement.order;
                    let dragUrl =  draggedElement.url;
                    let dragLetter =  draggedElement.letter;

                    draggedElement.locked = true;
                    droppedElement.locked = true;

                    this.setPuzzlePosition(dragOrder,dragUrl,dragLetter,droppedElement);
                }

                return false;
            },
            setPuzzlePosition(dragOrder,dragUrl,dragLetter,result) {
                this.images = this.images.filter(image => image.order != dragOrder);
                this.mobileImages = this.mobileImages.filter(image => image.order != dragOrder);
                this.smallMobileImages = this.smallMobileImages.filter(image => image.order != dragOrder);
                result.order = dragOrder;
                result.url = dragUrl;
                result.letter = dragLetter;

                if(this.images.length === 0) this.checkPuzzle();
            },

            checkPuzzle() {
                this.puzzleResolved = this.results.every(result => parseInt(result.result) === parseInt(result.order));
                if(this.puzzleResolved) {
                    this.updateCurrentPhase({id: 2, complete: false, current: true});
                    this.updateUserdata();
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/_variables.scss";

    #van-de-graff {
        display: flex;
        @include tablet {
            justify-content: center;
        }
        .puzzle-container,
        .puzzle-result{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
        .puzzle-container {
            width: 550px;
            @include tablet {
                width: 48%;
                height: calc(var(--doc-height)/1.5);
            }
            @include small-mobile {
                    width: 52%;
            }

            margin-right: 15px;
            img {
                margin: 10px;

                @include tablet {
                    margin: 3px;
                    &:nth-child(5) { margin: 3px 22px; }
                }
                @include small-mobile {
                    &:nth-child(5) { margin: 3px; }
                }
            }
        }
        .puzzle-resolved {
            width: 430px;
            padding: 25px 0;
            @include tablet {
                padding: 15px 0;
            }
            @include small-mobile {
                padding: 8px 0;
            }
            border: 1px solid $primary-purple-color;
            border-radius: 10px;
            background-color: rgba(#34439a,0.5);
            margin-right: 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
                position: relative !important;
                top: 0 !important;
                left: 0 !important;
                @include tablet {
                    width: calc(var(--doc-height)/3);
                }
                @include small-mobile {
                    width: calc(var(--doc-height)/4.5);
                }
            }
            p {
                margin-bottom: 0;
                color: #fff;
                &.title {
                    font-size: 25px;
                    line-height: 22px;
                }
                @include small-mobile {
                    font-size: 15px;
                    line-height: 15px;
                    margin-top: 9px;
                }
            }
        }
        .puzzle-result {
            width: 430px;
            padding: 25px 0;
            margin-left: 15px;
            @include tablet {
                width: 250px;
                padding: calc(100vw/55) 0;
            }
            @include small-mobile {
                width: 215px;
                padding: 2px;
                margin-left: 0;
            }

            border: 1px solid $primary-purple-color;
            border-radius: 10px;
            background-color: rgba(#34439a,0.5);
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .puzzle-result-image {
                width: 315px;
                height: 315px;
                @include tablet {
                    width: 165px;
                    height: 165px;
                }
                @include small-mobile {
                    width: 138px;
                    height: 138px;
                }
                display: flex;
                flex-wrap: wrap;
                border: 1px solid $primary-purple-color;
                .puzzle-result-drop-zone {
                    position: relative;
                    width: 103px;
                    height: 103px;
                    @include tablet {
                        width: 53px;
                        height: 53px;
                    }
                    @include small-mobile {
                        width: 44px;
                        height: 44px;
                    }

                    border: 1px solid rgba($primary-purple-color,0.5);
                    img {
                        position: absolute;
                        top: 0;
                    }
                    &:nth-child(1) { img {left: -1px;} }
                    &:nth-child(2) { img {left: -13px}}
                    &:nth-child(3) { img {right: -1px}}
                    &:nth-child(4) { img {left: -1px; top: -41px}}
                    &:nth-child(5) { img {left: -46px; top: -9px}}
                    &:nth-child(6) { img {right: -1px; top: -41px}}
                    &:nth-child(7) { img {left: -1px; top: -8px}}
                    &:nth-child(8) { img {left: -13px; top: -40px}}
                    &:nth-child(9) { img {right: -1px; top: -8px}}

                    @include tablet {
                        &:nth-child(2) { img {left: -7px}}
                        &:nth-child(4) { img {left: -1px; top: -21.5px}}
                        &:nth-child(5) { img {left: -23px; top: -6px}}
                        &:nth-child(6) { img {right: -1px; top: -22px}}
                        &:nth-child(7) { img {left: -1px; top: -4px}}
                        &:nth-child(8) { img {left: -7px; top: -21.5px}}
                        &:nth-child(9) { img {right: -1px; top: -5px}}
                    }

                    @include small-mobile {
                        &:nth-child(2) { img {left: -6px}}
                        &:nth-child(4) { img {left: -1px; top: -17px}}
                        &:nth-child(5) { img {left: -19px; top: -4px}}
                        &:nth-child(6) { img {right: -1px; top: -17px}}
                        &:nth-child(7) { img {left: -1px; top: -3px}}
                        &:nth-child(8) { img {left: -6px; top: -17.5px}}
                        &:nth-child(9) { img {right: -1px; top: -3px}}
                    }
                }
            }
            .puzzle-result-letter {
                width: calc(90% - 30px);
                margin-top: 20px;
                padding: 15px;
                @include small-mobile {
                    padding: 8px;
                    width: 70%;
                    margin-top: 10px;
                }
                display: flex;
                justify-content: center;
                border-radius: 10px;
                background-color: $primary-color;
                -webkit-transition: 0.3s;
                transition: 0.3s;
                span {
                    position: relative;
                    width: 20px;
                    height: 30px;
                    @include small-mobile {
                        width: 15px;
                        height: 20px;
                    }
                    margin-right: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &:after {
                        content: '';
                        border-radius: 2px;
                        position: absolute;
                        width: 100%;
                        height: 2px;
                        bottom: 0;
                        background-color: #fff;
                    }
                    &.has-letter {
                        color: $primary-blue-color;
                        -webkit-transition: 0.3s;
                        transition: 0.3s;
                        &:after {
                            background-color: $primary-blue-color;
                            -webkit-transition: 0.3s;
                            transition: 0.3s;
                        }
                    }
                }
                &.puzzle-result-letter-finish {
                    background-color: $primary-blue-color;
                    span {
                        &.has-letter {
                            color: #fff;
                            &:after {
                                background-color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
