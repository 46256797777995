<template>
    <div id="complete-sectors">
        <TooltipHelpButton v-if="$screenSize.isMobileOrSmallTablet.value || $screenSize.isDesktop.value">
            <template #button-message>
                <p v-html="$t('headerSectorComplete')"></p>
            </template>
        </TooltipHelpButton>
        <span v-if="!$screenSize.isMobileOrSmallTablet.value && !$screenSize.isDesktop.value" v-html="$t('headerSectorComplete')"></span>
        <div v-bind:class="{'sector' : true, 'complete' : isCompleteSector(1), 'current' : isCurrentSector(1)}">
            <img src="./../../../assets/sectors/sector_1.png" alt="">
        </div>
        <div v-bind:class="{'sector' : true, 'complete' : isCompleteSector(2), 'current' : isCurrentSector(2)}">
            <img src="./../../../assets/sectors/sector_2.png" alt="">
        </div>
        <div v-bind:class="{'sector' : true, 'complete' : isCompleteSector(3), 'current' : isCurrentSector(3)}">
            <img src="./../../../assets/sectors/sector_3.png" alt="">
        </div>
        <div v-bind:class="{'sector' : true, 'complete' : isCompleteSector(4), 'current' : isCurrentSector(4)}">
            <img src="./../../../assets/sectors/sector_4.png" alt="">
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    import TooltipHelpButton from '@/components/buttons/tooltip-help-button/tooltip-help-button.vue';

    export default {
        name: "header-nav-complete-sector",
        components: {
            TooltipHelpButton
        },
        computed: {
            ...mapGetters([
                'getCurrentState',
            ])
        },
        methods: {
            isCurrentSector: function(idSector) {
                let sector = this.getCurrentState().sector;
                return sector.id === idSector && sector.current === true;
            },
            isCompleteSector: function(idSector) {
                let sector = this.getCurrentState().sector;
                return sector.id > idSector;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/_variables.scss";

    #complete-sectors {
        display: flex;
        align-items: center;

        padding: 15px;
        @include tablet-and-desktop {
            padding: 10px;
        }

        border-radius: 7px;

        img {
            width: 25px;
        }
        span {
            color: #fff;
            text-transform: uppercase;
        }
        .sector {
            margin-left: 15px;
            width: 50px;
            height: 50px;
            @include big-desktop {
                margin-left: calc(100vw/160);
                width: calc(100vw/38);
                height: calc(100vw/38);
            }

            @include desktop {
                margin-left: calc(100vw/140);
                width: calc(100vw/34);
                height: calc(100vw/34);
            }
            @include tablet {
                margin-left: calc(100vw/84.4);
                width: calc(100vw/30.1);
                height: calc(100vw/30.1);
            }

            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $primary-color;
            border: 1px solid #415997;
            -webkit-transition: 0.3s;
            transition: 0.3s;
            img {
                @include tablet {
                    width: calc(100vw/42.2);
                    height: calc(100vw/42.2);
                }

                opacity: 0.4;
                -webkit-transition: 0.3s;
                transition: 0.3s;
            }
            &.complete {
                background-color: rgba($primary-blue-color, 0.7);
                img {
                    opacity: 1;
                }
            }
            &.current {
                border: 1px solid $primary-blue-color;
                img {
                    opacity: 1;
                }
            }
        }
    }
</style>
