<template>
    <div>
        <div v-if="$screenSize.isMobileOrSmallTablet.value" class="mobile-modal-title">
            <p>{{ $t('hitosElectricidad') }}</p>
        </div>
        <div id="electric-discoveries">
        <div class="card-electric-discovery">
            <div class="header-electric-discovery">
                <p>1800</p>
                <p>{{$t('pilaVoltaTitulo') }}</p>
            </div>
            <img :src="require(`./assets/pila-volta.png`)" alt="">
            <div class="body-electric-discovery" v-html="$t('pilaVoltaContenido')"> </div>
        </div>

        <div class="card-electric-discovery">
            <div class="header-electric-discovery">
                <p>1880</p>
                <p>{{ $t('bombillaTitulo') }}</p>
            </div>
            <img :src="require(`./assets/bombilla.png`)" alt="">
            <div class="body-electric-discovery" v-html="$t('bombillaContent')"> </div>
        </div>

        <div class="card-electric-discovery">
            <div class="header-electric-discovery">
                <p>1884</p>
                <p>{{ $t('wimshurstTitulo') }} </p>
            </div>
            <img :src="require(`./assets/maquina-wimshurst.png`)" alt="">
            <div class="body-electric-discovery" v-html="$t('wimshurstContent')"> </div>
        </div>

        <div class="card-electric-discovery">
            <div class="header-electric-discovery">
                <p>1891</p>
                <p>{{ $t('bobinaTeslaTitulo') }}</p>
            </div>
            <img :src="require(`./assets/bobina-tesla.png`)" alt="">
            <div class="body-electric-discovery" v-html="$t('bobinaTeslaContent')"> </div>
        </div>
    </div>
    </div>
</template>

<script>
    export default {
        name: "electric-discoveries"
    }
</script>

<style lang="scss">

    @import "@/scss/_variables.scss";

    #electric-discoveries {
        display: flex;
        @include tablet {
            gap: 8px !important;
            height: calc(85vh - 58px);
            margin-top: 1vh;
        }
        .card-electric-discovery {
            width: calc(250px - 40px);
            flex-basis: calc(250px - 40px);
            padding: 10px 20px 20px 20px;
            @include tablet {
                padding: 10px 12px;
                width: 25%;
                flex-basis: auto;
                margin-right: 0;
                max-height: calc(100vh - 60px);
                overflow-y: scroll;
            }
            margin-right: 20px;
            border-radius: 10px;
            border: 1px solid $primary-blue-color;
            background-color: $secondary-color;
            &:last-child {
                margin-right: 0;
            }
            .header-electric-discovery {
                padding: 15px;
                p {
                    margin: 0;
                    color: $primary-blue-color;
                    font-weight: bold;
                    &:first-child {
                        font-size: 18px;
                        line-height: 20px;
                    }
                }
                @include tablet {
                    padding: 10px;
                    line-height: 1.1rem;
                }
                background-color: $primary-color;
                border-radius: 10px;
            }
            img {
                width: 100%;
                margin: 15px 0;
                @include tablet {
                    margin: 7px 0;
                    width: 70%;
                }
            }
            .body-electric-discovery {
                color: #fff;
                margin: 0;
                span {
                    color: $primary-blue-color;
                }
                p {
                    color: #fff;
                    margin: 0;
                    span {
                        color: $primary-blue-color;
                    }
                    @include tablet {
                        line-height: 1.1rem;
                    }
                }

            }
        }
    }
</style>