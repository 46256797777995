<template>
    <div id="name-game">
        <div class="box-drop box-one">
            <p>{{ $t('sector5ArrastrarTitle') }}</p>
            <draggable v-if="!solvedDrag" class="box-boxes"  group="glifoname" :list="results">
                <div  class="box-dropped" v-for="result in results" @drop="onDrop($event, result.letter)">
                    <span v-if="result.url === null"></span>
                    <img v-else :src="require(`${result.url}`)" alt="">
                </div>
            </draggable>
            <div v-else class="box-boxes">
                <div class="box-dropped solved">
                    <span>{{ elements[4].letter }}</span>
                </div>
                <div class="box-dropped solved">
                    <span>{{ elements[1].letter }}</span>
                </div>
                <div class="box-dropped solved">
                    <span>{{ elements[2].letter }}</span>
                </div>
                <div class="box-dropped solved">
                    <span>{{ elements[3].letter }}</span>
                </div>
            </div>
        </div>

        <div class="box-drop">
            <p>{{ $t('sector5ArrastrarTitle2') }}</p>
            <draggable class="box-drag-content" group="glifoname" v-model="elements" @start="isDragging=false" @end="isDragging=false" :move="startDrag" >
                <div class="box-drag" v-for="element in elements" draggable="true">
                    <span>{{ element.letter }}</span>
                    <img :src="require(`${element.image}`)" alt="">
                </div>
            </draggable>
        </div>
    </div>
</template>

<script>

    import draggable from 'vuedraggable'

    import { mapMutations } from 'vuex'
    import { mapGetters } from 'vuex'

    export default {
        name: "name-dragdrop",
        data() {
            return {
                solvedDrag: false,
                name: 'ABCD',
                elements: [
                    {image: './assets/glifo1.png', letter: '<'},
                    {image: './assets/glifo2.png', letter: '*'},
                    {image: './assets/glifo3.png', letter: '/*'},
                    {image: './assets/glifo4.png', letter: '4b'},
                    {image: './assets/glifo5.png', letter: '+-'},
                    {image: './assets/glifo6.png', letter: '/-'},
                    {image: './assets/glifo7.png', letter: '-'},
                    {image: './assets/glifo8.png', letter: '/*'},
                    {image: './assets/glifo9.png', letter: '/'},
                    {image: './assets/glifo11.png', letter: '--'},
                    {image: './assets/glifo11.png', letter: '}'},
                    {image: './assets/glifo12.png', letter: '{'},
                    {image: './assets/glifo13.png', letter: '[]'},
                    {image: './assets/glifo14.png', letter: '*-'},
                    {image: './assets/glifo15.png', letter: '+'},
                    {image: './assets/glifo16.png', letter: '**'},
                    {image: './assets/glifo17.png', letter: '$'}
                ],
                results: [],

            }
        },
        components: {
            draggable,
        },
        computed: {
            ...mapGetters(['getUsername'])
        },
        created() {
            this.fragmentUserName();
        },
        methods: {
            ...mapMutations(['updateCurrentPhase','updateCurrentSector','updateUserdata']),
            fragmentUserName: function () {

                let name = this.getUsername().split("");

                if(name == 'visitante') name = '¡TU!';
                if(name == 'visitor') name = 'YOU!';

                let lastName = '';
                if(name[3]) { lastName = name[3]; }
                else { lastName = '0'; }

                this.results  = [
                    {result: 1, letter: name[0].toUpperCase() , url: null},
                    {result: 2, letter: name[1].toUpperCase() , url: null},
                    {result: 3, letter: name[2].toUpperCase() , url: null},
                    {result: 4, letter: lastName.toUpperCase() , url: null},
                ];

                this.elements[4].letter = name[0].toUpperCase();
                this.elements[1].letter = name[1].toUpperCase();
                this.elements[2].letter = name[2].toUpperCase();
                this.elements[3].letter = lastName.toUpperCase();

            },
            startDrag : function ({ relatedContext, draggedContext }) {

                let droppedElement = relatedContext.element;
                let draggedElement = draggedContext.element;

                if(draggedElement.letter === droppedElement.letter) {

                    let dragLetter = draggedElement.order;
                    let dragUrl =  draggedElement.image;

                    this.setPuzzlePosition(dragLetter,dragUrl,droppedElement)
                }

                return false;

            },
            setPuzzlePosition(dragLetter,dragUrl,result) {

                this.elements = this.elements.filter(element => element.letter != dragLetter);
                result.url = dragUrl;

                let resultsWithLetter = this.results.filter(result => result.url == null);

                /*
                if(resultsWithLetter.length === 3) {
                    this.updateCurrentPhase({id: 2, complete: false, current: true});
                    this.updateUserdata()
                }
                else if(resultsWithLetter.length === 2) {
                    this.updateCurrentPhase({id: 3, complete: false, current: true});
                    this.updateUserdata()
                }
                else if(resultsWithLetter.length === 1) {
                    this.updateCurrentPhase({id: 4, complete: false, current: true});
                    this.updateUserdata()
                }*/

                if(resultsWithLetter.length === 0) {
                    this.solvedDrag = true;
                    setTimeout(() => {
                        this.updateCurrentSector({id: 6, complete: false, current: true});
                        this.updateUserdata();
                    },2000)
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/_variables.scss";

    #name-game {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
            text-transform: uppercase;
            color: #fff;
            text-align: center;
            margin: 0 0 20px 0;
        }
        .box-drop {
            width: calc(100% - 80px);
            &.box-one {
                width: 500px;
                margin-bottom: 50px;
                @include mobile {
                    width: calc(100% - 80px);
                    margin-bottom: 4px;
                    flex-direction: row;
                }
            }
            padding: 40px;
            border-radius: 20px;
            border: 1px solid rgba($primary-blue-color,0.4);
            background-color: rgba(#293580,0.3);
            display: flex;
            flex-direction: column;
            @include mobile {
                padding: 8px;
                &:not(.box-one) {
                    padding-bottom: 0;
                }
                p {
                    margin-bottom: 4px;
                }
            }
            justify-content: center;
            align-items: center;
            .box-boxes {
                width: 425px;
                display: flex;
                justify-content: space-between;
                @include mobile {
                    width: unset;
                    gap: 10px;
                    margin-left: 20px;
                }
                .box-dropped {
                    width: 80px;
                    height: 80px;
                    @include mobile {
                        width: 60px;
                        height: 60px;
                    }
                    @include x-small-mobile {
                        width: 50px;
                        height: 50px;
                    }
                    background-color: #17215d;
                    border-radius: 15px;
                    border: 1px solid rgba($primary-blue-color,0.5);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    span {
                        font-size: 28px;
                        line-height: 30px;
                        color: $primary-blue-color;
                    }
                    &.solved {
                        border: 2px solid $primary-blue-color;
                        -webkit-box-shadow: 0px 1px 30px -4px rgba($primary-blue-color,0.71);
                        box-shadow: 0px 1px 30px -4px rgba($primary-blue-color,0.71);
                    }
                }
            }
            .box-drag-content {
                display: flex;
                flex-wrap: wrap;
                margin-top: 30px;
                @include mobile {
                    width: 100%;
                    margin-top: 0;
                    gap: 6px;
                    flex-wrap: nowrap;
                    overflow-x: auto;
                    &::-webkit-scrollbar {
                        height: 8px;
                    }
                }
                .box-drag {
                    position: relative;
                    width: 80px;
                    height: 80px;
                    margin: 0 10px 10px 0;
                    @include mobile {
                        flex-shrink: 0; 
                        width: 55px;
                        height: 55px;
                        margin: 6px 0 16px 0;
                    }
                    @include x-small-mobile {
                        width: 45px;
                        height: 45px;
                    }
                    border-radius: 15px;
                    background-color: #17215d;
                    border: 1px solid rgba($primary-blue-color,0.5);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    span {
                        position: absolute;
                        top: 5px;
                        right: 5px;
                        color: $primary-blue-color;
                        @include mobile {
                            top: 4px;
                            font-weight: bold;
                            background-color: black;
                            line-height: 14px;
                            padding: 1px 2px;
                            border-radius: 4px;
                        }
                    }
                    &:hover {
                        cursor: pointer;
                        border-color: $primary-blue-color;
                    }
                }
            }
        }
    }
</style>
