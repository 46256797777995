<template>
    <header>
        <div class="card-header" v-if="$screenSize.isMobileOrSmallTablet.value">
            <header-nav-username></header-nav-username>
        </div>
        <div class="card-header">
            <header-nav-username v-if="!$screenSize.isMobileOrSmallTablet.value"></header-nav-username>
            <div class="lines-divisor" v-if="!$screenSize.isMobileOrSmallTablet.value"></div>
            <header-nav-complete-sector></header-nav-complete-sector>
        </div>

        <div class="card-header">
            <header-nav-complete-phases></header-nav-complete-phases>
        </div>

        <div class="card-header">
            <slot name="header-nav-mail"> </slot>
        </div>

        <div class="card-header">
            <header-nav-time></header-nav-time>
        </div>

        <div class="card-header">
            <header-paused-game></header-paused-game>
        </div>
        <div style="position: absolute; top: 50px; right: 20px; color: white">{{ $screenSize.innerWidth. value }} x {{ $screenSize.innerHeight.value }}</div>
    </header>
</template>

<script>
    import HeaderNavUsername from './mini-components/header-nav-username';
    import HeaderNavCompleteSector from './mini-components/header-nav-complete-sector';
    import HeaderNavCompletePhases from './mini-components/header-nav-complete-phases';
    import HeaderNavTime from './mini-components/header-nav-time';
    import HeaderPausedGame from './mini-components/header-nav-paused';

    export default {
        name: "header-body",
        components: {
            HeaderNavUsername,
            HeaderNavCompleteSector,
            HeaderNavCompletePhases,
            HeaderNavTime,
            HeaderPausedGame
        }
    }
</script>

<style lang="scss">
    @import "@/scss/_variables.scss";

    header {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .card-header {
            display: flex;
            align-items: center;
            background-color: rgba($primary-color, 0.4);
            border: 1px solid rgba(#415997, 0.4);
            border-radius: 7px;

            margin-left: 15px;
            @include big-desktop {
                margin-left: 10px;
            }
            @include tablet {
                margin-left: calc(100vw/120.4);
            }

            &:first-child {
                margin-left: 0;
            }
        }
        .lines-divisor {
            position: relative;
            margin: 0 25px;
            @include desktop {
                margin: 0 10px;
            }

            @include big-desktop {
                margin: 0 15px;
            }

            width: 1px;
            height: 35px;
            &:before,
            &:after {
                content: '';
                opacity: 0.25;
                position: absolute;
                width: 1px;
                background-color: #fff;
                height: calc(50% - 5px);
            }
            &:before {
                top: 0;
            }
            &:after {
               bottom: 0;
            }
        }
        #header-mail {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 7px;
            -webkit-transition: 0.3s;
            transition: 0.3s;
            .mail-icon {
                display: flex;
                align-items: center;
                padding: 15px;
                @include tablet-and-desktop {
                    padding: 10px;
                }
                cursor: pointer;

                min-width: 50px;
                min-height: 50px;
                @include big-desktop {
                    min-width: calc(100vw/38);
                    min-height: calc(100vw/38);
                }
                @include desktop {
                    min-width: calc(100vw/34);
                    min-height: calc(100vw/34);
                }
                @include tablet {
                    min-width: calc(100vw/30.1);
                    min-height: calc(100vw/30.1);
                }

                position: relative;
                img {
                    width: 25px;
                    height: auto;
                    margin-top: 5px;
                    margin-left: 10px;

                    @include tablet {
                        margin-top: 0;
                        margin-left: 0;
                    }
                }
                span {
                    position: absolute;
                    top: 10px;
                    right: 5px;
                    width: 30px;
                    height: 30px;
                    @include big-desktop {
                        top: 14px;
                        right: 6px;
                        font-size: 12px;
                        width: 22px;
                        height: 22px;
                    }

                    @include tablet {
                        top: 12px;
                        right: 8px;
                        font-size: 12px;
                        width: 15px;
                        height: 15px;
                    }

                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $red-color;
                    color: #fff;
                }
            }
            &:hover {
                cursor: pointer;
                background-color: $primary-blue-color;
            }
        }
    }
</style>
