<template>
    <div id="step-three-environment">
        <HowToPlayButton
            v-if="$screenSize.isMobileOrSmallTablet.value && !stepSolved"
            class="how-to-play-button">
                <template slot="modal-content">
                    <div class="text-top-box">
                        <p>
                            {{ $t('sector3SistemaVoltaStep1ThreeTitle') }}
                        </p>
                    </div>
                </template>
        </HowToPlayButton>
        <div v-if="$screenSize.isTablet.value && !$screenSize.isMobile.value && stepSolved" class="solved-text tablet">
            <p v-html="$t('sector3SistemaVoltaStep1ThreeCorrect')"> </p>
        </div>
        <div v-if="$screenSize.isMobile.value && stepSolved" class="solved-text-mobile">
            <p v-html="$t('sector3SistemaVoltaStep1ThreeCorrect')"> </p>
        </div>
        <div id="step-three">
            <div v-if="!$screenSize.isMobileOrSmallTablet.value" class="text-box-top">
                <p v-if="!stepSolved">
                    {{ $t('sector3SistemaVoltaStep1ThreeTitle') }}
                </p>
                <div class="solved-text" v-else>
                    <p v-html="$t('sector3SistemaVoltaStep1ThreeCorrect')"> </p>
                </div>
            </div>
            <div class="content-step-three" :class="{'solved': stepSolved}">
                <img src="./assets/stepthree/mapa.png" alt="">
                <div id="solution" v-bind:class="{'box' : true, 'incorrect' : (total > 375), 'correct' : stepSolved}">{{ total }}</div>
                <div :id="box.id" v-bind:class="{'box': true, 'active': getSolved(box.id)}" class="box" @click="setBox(box.value, box.id)" v-for="box in boxes">{{ box.name }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapMutations } from 'vuex';
    import { mapGetters } from 'vuex';

    import HowToPlayButton from '@/components/buttons/how-to-play-button/how-to-play-button';

    export default {
        name: "step-three",
        components: {
            HowToPlayButton
        },
        data() {
            return {
                total: 0,
                stepSolved: false,
                boxes:  [
                    {name: 'so', value: 45, id: 'so'},
                    {name: 's1', value: 100, id: 's1'},
                    {name: 's2', value: 80, id: 's2'},
                    {name: 'E', value: 240, id: 'e'},
                    {name: 'ne', value: 75, id: 'ne'},
                    {name: 'n2', value: 130, id: 'n2'},
                    {name: 'n1', value: 45, id: 'n1'}
                ]
            }
        },
        computed: {
            ...mapGetters(['getCurrentState']),
        },
        created() {
            this.checkPhaseStatus();
        },
        methods: {
            ...mapMutations(['updateCurrentPhase','updateUserdata']),
            checkPhaseStatus : function() {
                let state = this.getCurrentState();
                if(state.phase.id > 3) {
                    this.total = 375;
                    this.stepSolved = true;
                }
            },
            setBox: function (total, id) {
                let element = document.getElementById(id);
                if(element.classList.contains('active')) {
                    this.total -= total;
                }
                else {
                    this.total += total;
                }

                element.classList.toggle('active');

                this.stepSolved = this.total === 375;
                if(this.stepSolved) {
                    this.updateCurrentPhase({id: 4, complete: false, current: true});
                    this.updateUserdata();
                }
            },
            getSolved: function (id) {
                return this.stepSolved && ['so','s2','ne','n2','n1'].includes(id);
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/_variables.scss";

    #step-three-environment {
        @include tablet {
            display: grid;
            place-content: center;
            height: calc(var(--doc-height) - 49.5px);
        }
    }

    .how-to-play-button {
        position: absolute;
        top: 20px;
        left: 20px;
    }

    #step-three {
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 1000px;
        min-width: 1000px;
        @include mobile {
            transform: scale(0.57);
            margin-top: 10px;
        }
        @include x-small-mobile {
            transform: scale(0.5);
        }
        .text-box-top {
            flex-basis: 100%;
            width: 100%;
            height: 92px;
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            p {
                color: #fff;
            }
        }
        .content-step-three {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            @include mobile {
                &.solved {
                    left: calc(570px - 100vw);
                }
            }
            @include x-small-mobile {
                &.solved {
                    left: calc(500px - 100vw);
                }
            }
            img {
                width: 890px;
                height: auto;
            }
            .box {
                position: absolute;
                padding: 10px 15px;
                @include mobile {
                    padding: 12px 11px;
                    font-size: 26px;
                }
                @include x-small-mobile {
                    padding: 14px 13px;
                    font-size: 30px;
                }
                background-color: $primary-color;
                border-radius: 10px;
                border: 1px solid $primary-blue-color;
                color: #fff;
                text-transform: uppercase;
                -webkit-transition: 0.3s;
                transition: 0.3s;
                &:hover,
                &.active {
                    cursor: pointer;
                    -webkit-box-shadow: 0px 1px 30px -4px rgba($primary-blue-color,0.71);
                    box-shadow: 0px 1px 30px -4px rgba($primary-blue-color,0.71);
                }
                &.active {
                    background-color: $primary-blue-color;
                    color: #fff;
                }
                &.incorrect {
                    border-color: $red-color;
                    background-color: rgba($red-color,0.5);
                    color: #fff;
                }
                &.correct {
                    border-color: $green-color;
                    background-color: rgba($green-color,0.5);
                    color: #fff;
                }
                &#solution {
                    top: 66px;
                    right: 684px;
                }
                &#so {
                    top: 187px;
                    left: 51px;
                }
                &#s1 {
                    top: 332px;
                    left: 109px;
                }
                &#s2 {
                    top: 392px;
                    left: 221px;
                }
                &#e {
                    top: 393px;
                    left: 634px;
                }
                &#ne {
                    top: 171px;
                    left: 848px;
                }
                &#n2 {
                    top: 97px;
                    left: 727px;
                }
                &#n1 {
                    top: 38px;
                    left: 630px;
                }
            }
        }
    }

    .solved-text {
        width: 100%;
        p {
            text-align: center;
            margin: 0;
            &:first-child {
                color: $green-color !important;
                font-size: 30px;
                line-height: 35px;
                font-weight: bold;
                margin-bottom: 5px;
            }
        }
        &.tablet {
            p {
                font-size: 24px;
                line-height: 26px;
            }
        }
    }

    .solved-text-mobile {
        width: 34%;
        @include wide-mobile {
            width: 28%;
        }
        position: absolute;
        right: 0;
        height: calc(var(--doc-height) - 75.5px);
        display: flex;
        align-items: center;
        p {
            text-align: center;
            margin: 0;
            &:first-child {
                color:  $green-color !important;
                font-size: 16px;
                line-height: 20px;
                font-weight: bold;
                margin-bottom: 5px;
            }
        }
    }
</style>
