<template>
    <div id="paused-game">
        <span v-if="!$screenSize.isMobileOrSmallTablet.value && !$screenSize.isDesktop.value" v-html="$t('pauseGame')"></span>
        <div @click="pausedGame()" class="button-paused">
            <img src="./../../../assets/header-nav/paused.png" alt="">
        </div>
    </div>
</template>

<script>

    import { mapMutations } from 'vuex'

    export default {
        name: "header-nav-paused",
        methods: {
            ...mapMutations(['updatePausedGame']),
            pausedGame: function () {
                this.updatePausedGame({pausedGame: true});
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    #paused-game {
        display: flex;
        align-items: center;
        padding: 0 20px;
        @include tablet-and-desktop {
            padding: 0 6px;
        }

        span {
            color: #fff;
            text-transform: uppercase;
        }
        .button-paused {
            margin-left: 15px;
            @include tablet-and-desktop {
                margin-left: 0;
            }

            width: 50px;
            height: 50px;
            @include desktop {
                width: calc(100vw/34);
                height: calc(100vw/34);
            }
            @include tablet {
                width: calc(100vw/30.1);
                height: calc(100vw/30.1);
            }

            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $primary-blue-color;
            transition: 0.3s;
            &:hover {
                cursor: pointer;
                background-color: $secondary-blue-color;
            }
        }
    }

</style>
