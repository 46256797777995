<template>
    <div class="directories">
        <div class="directory-box">
            <directory directoryTitle = "Cecoel App"
                       directoryImage = "ceocel-app.png"
                       directoryModalTitle = "Cecoel App"
                       :directoryBlocked="this.getCurrentState().phase.id == 1"
            >
                <template slot="modal-content">
                   <cecoel-app></cecoel-app>
                </template>
            </directory>
        </div>
        <div class="directory-box">
            <directory :directoryTitle = "$t('sector2Calendar')"
                       directoryImage = "calendar.png"
                       :directoryModalTitle = "$t('sector2Calendar')"
            >
                <template slot="modal-content">
                    <calendar></calendar>
                </template>
            </directory>
        </div>
        <clues-button v-if="$screenSize.isMobileOrSmallTablet.value">
            <template slot="modal-content">
                <desktop-left-two></desktop-left-two>
            </template>
        </clues-button>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    import Directory from '@/components/directory/directory.vue';
    import CecoelApp from './../games/ceoel-app/cecoel-app';
    import Calendar from './../games/calendar/calendar';
    import CluesButton from '@/components/buttons/clues-button/clues-button.vue';
    import DesktopLeftTwo from './desktop-left-two.vue';

    export default {
        name: "card-desktop-right",
        components: {
            Directory,
            CecoelApp,
            Calendar,
            CluesButton,
            DesktopLeftTwo
        },
        computed: {
            ...mapGetters(['getCurrentState'])
        },
    }
</script>
