<template>
    <div style="padding: 0;">
        <div v-if="!solved" class="buttons-container">
            <HowToPlayButton
                class="how-to-play-button">
                    <template slot="modal-content">
                        <div class="text-top-box">
                            <div class="info-box">
                                <img src="./assets/info-circle.png" alt="">
                                <span>{{ $t('sector4ProyectoSorianSistemaFuncionamiento') }}</span>
                            </div>
                        </div>
                    </template>
            </HowToPlayButton>
            <CoordinatesButton
                class="coordinates-button">
                    <template slot="modal-content">
                        <div class="text-top-box">
                            <coordinates></coordinates>
                        </div>
                    </template>
            </CoordinatesButton>
        </div>

        <div class="square-floor-wrapper">
            <div class="square-floor"></div>
        </div>

        <div id="electric-system">
            <div v-if="solved" class="step-solved">
                <sector-complete
                        :sectorText="$t('sector4ProyectoSorianSistemaCorrect')"
                        v-on:pass-sector="setNextSector"></sector-complete>
            </div>
            <div v-else class="electric-system-content">
                <draggable class="scroll-elements-content"
                        group="electricSystem"
                        v-model="naturalElectricElements"
                        @start="isDragging=false"
                        @end="isDragging=false"
                        :move="startDrag"
                        :key="'scroll-elements-content ' + naturalElectricElements.length"
                    >
                    <div
                        v-if="natural.showedElement && solvedElectric === natural.solvedElectric"
                        class="scroll-element-box"
                        :id="natural.key"
                        v-for="(natural,index) in naturalElectricElements"
                    >
                        <img :src="require(`${natural.image}`)" alt="">
                    </div>
                    <div v-if="solvedNatural && !solvedElectric" @click="solvedElectric = true" class="button-electric">
                        {{ $t('sector4ProyectoSorianSistemaContinua') }}
                    </div>
                </draggable>
                <div class="map-content">
                    <div class="numbers">
                        <div class="number" :id="'number-' + number" v-for="number in numbers">{{ number }}</div>
                    </div>

                    <draggable class="square" group="electricSystem" :list="positions" :key="'square' + positions.length">
                        <div class="box-square"
                            @mouseover="hoverEnterLetterNumber(position.position)"
                            @mouseleave="hoverLeaveLetterNumber(position.position)"
                            @dragenter="hoverEnterLetterNumber(position.position)"
                            @dragleave="hoverLeaveLetterNumber(position.position)"
                            :id="position.position"
                            v-for="position in positions">
                        </div>
                    </draggable>

                    <div class="image-inserts">
                        <img :id="image.position" v-for="image in imageInsert" :src="require(`${image.url}`)" alt="">
                    </div>
                    <div class="letters">
                        <div class="letter" :id="'letter-' + letter" v-for="letter in letters">{{ letter }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapMutations} from 'vuex';
    import { useScreenSize } from '@/composables/screenSize';

    import SectorComplete from '@/components/sector-complete/sector-complete.vue';
    import draggable from 'vuedraggable';
    import HowToPlayButton from '@/components/buttons/how-to-play-button/how-to-play-button';
    import CoordinatesButton from '@/components/buttons/coordinates-button/coordinates-button.vue';
    import Directory from '@/components/directory/directory.vue';
    import Coordinates from '../coordenates/coordenates';

    export default {
        name: "electric-system",
        components: {
            SectorComplete,
            draggable,
            HowToPlayButton,
            Directory,
            Coordinates,
            CoordinatesButton
        },
        data() {
            return {
                isMobileOrSmallTablet: useScreenSize().isMobileOrSmallTablet,
                solved: false,
                solvedNatural: false,
                solvedElectric: false,
                electricElements: [
                    {image: './assets/mountains.png', key: 'mountains', position: '2A', showedElement: true, solvedElectric: false},
                    {image: './assets/fabric.png', key: 'fabric', position: '5A', showedElement: true, solvedElectric: false},
                    {image: './assets/house.png', key: 'house', position: '7A', showedElement: true, solvedElectric: false},
                    {image: './assets/birds.png', key: 'birds', position: '6D', showedElement: true, solvedElectric: false},
                    {image: './assets/birds.png', key: 'birds-2', position: '4D', showedElement: true, solvedElectric: false},
                    {image: './assets/natural-reserve.png', key: 'natural-reserve', position: '7E', showedElement: true, solvedElectric: false},
                    {image: './assets/torre-distribucion.png', key: 'torreDistribucion', position: '5C', showedElement: true, solvedElectric: true},
                    {image: './assets/torre-pajaros.png', key: 'torrePajaros', position: '5D', showedElement: true, solvedElectric: true},
                    {image: './assets/torre-transporte.png', key: 'torreTransporte', position: '5E', showedElement: true, solvedElectric: true}
                ],
                electricElementsMobile: [
                    {image: './assets/mobile/mountains.png', key: 'mountains', position: '2A', showedElement: true, solvedElectric: false},
                    {image: './assets/mobile/fabric.png', key: 'fabric', position: '5A', showedElement: true, solvedElectric: false},
                    {image: './assets/mobile/house.png', key: 'house', position: '7A', showedElement: true, solvedElectric: false},
                    {image: './assets/mobile/birds.png', key: 'birds', position: '6D', showedElement: true, solvedElectric: false},
                    {image: './assets/mobile/birds.png', key: 'birds-2', position: '4D', showedElement: true, solvedElectric: false},
                    {image: './assets/mobile/natural-reserve.png', key: 'natural-reserve', position: '7E', showedElement: true, solvedElectric: false},
                    {image: './assets/mobile/torre-distribucion.png', key: 'torreDistribucion', position: '5C', showedElement: true, solvedElectric: true},
                    {image: './assets/mobile/torre-pajaros.png', key: 'torrePajaros', position: '5D', showedElement: true, solvedElectric: true},
                    {image: './assets/mobile/torre-transporte.png', key: 'torreTransporte', position: '5E', showedElement: true, solvedElectric: true}
                ],
                numbers: [1, 2, 3, 4, 5, 6, 7, 8],
                letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'],
                positions: [],
                imageInsert: [],

            }
        },
        created() {
            this.generateMap();
        },
        computed: {
            naturalElectricElements: function () {
                if (this.isMobileOrSmallTablet.value) {
                    return this.electricElementsMobile;
                } 

                return this.electricElements;
            }
        },
        methods: {
            ...mapMutations(['updateCurrentPhase', 'updateCurrentSector', 'updateUserdata']),
            generateMap: function () {
                this.letters.forEach((letter) => {
                    this.numbers.forEach((number) => {
                        let element = {position: (number + '' + letter)};
                        this.positions.push(element);
                    })
                })
            },
            hoverEnterLetterNumber: function (position) {

                let numberId = 'number-' + position[0];
                let letterId = 'letter-' + position[1];

                document.getElementById(position).classList.add('active');
                document.getElementById(numberId).classList.add('active');
                document.getElementById(letterId).classList.add('active');
            },
            hoverLeaveLetterNumber: function (position) {
                let numberId = 'number-' + position[0];
                let letterId = 'letter-' + position[1];

                document.getElementById(position).classList.remove('active');
                document.getElementById(numberId).classList.remove('active');
                document.getElementById(letterId).classList.remove('active');
            },

            startDrag: function ({relatedContext, draggedContext}) {
                let droppedElement = relatedContext.element;
                let draggedElement = draggedContext.element;

                if (draggedElement.position == droppedElement.position) {
                    let dragPosition = draggedElement.position;
                    let dragImage = draggedElement.image;

                    this.setPuzzlePosition(dragPosition, dragImage, droppedElement.position);
                }
                return false;

            },
            setPuzzlePosition(dragPosition, dragImage, position) {
                this.electricElements = this.electricElements.filter(image => image.position != dragPosition);
                this.electricElementsMobile = this.electricElementsMobile.filter(image => image.position != dragPosition);

                let newPosition = position.split("").reverse().join("");
                let newImmage = {position: newPosition, url: dragImage};

                if (!(this.imageInsert.filter(e => e.position == newPosition).length > 0)) {
                    this.imageInsert.push(newImmage);
                }

                if (this.naturalElectricElements.length <= 3) {
                    this.solvedNatural = true;
                    this.updateCurrentPhase({id: 2, complete: false, current: true});
                    this.updateUserdata();

                    if(this.naturalElectricElements.length === 0) {
                        setTimeout(() => {
                            this.solved = true;
                        },2000);
                    }
                }
            },
            setNextSector: function () {
                this.updateCurrentSector({id: 4, complete: true, current: false});
                this.updateCurrentSector({id: 5, complete: false, current: true});
                this.updateCurrentPhase({id: 1, complete: false, current: true});
                this.updateUserdata();
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/_variables.scss";

    .buttons-container {
        position: absolute;
        top: 20px;
        left: 20px;
        display: flex;
        gap: 12px;
    }

    .button-electric {
        @include x-small-mobile {
            width: 74%;
        }
    }

    .square-floor-wrapper {
        perspective: 190710px;
        position: relative;
        overflow: visible;
        .square-floor {
            position: absolute;
            width: 340px;
            height: 340px;
            transform: rotateX(55deg) rotateY(0.9deg) rotateZ(-46deg);
            transform-origin: center;
            top: -52px;
            right: 106px;
            z-index: 2;
            background-color: #75a319;
            border-left: 2px solid #3a5313;
            border-bottom: 2px solid #3a5313;
            display: flex;
            flex-wrap: wrap;
            @include x-small-mobile {
                width: 273px;
                height: 280px;
            }
        }
    }

    #electric-system {
        perspective: 190710px;
        overflow: visible;
        .electric-system-content {
            overflow: visible;
            margin: 50px 0;
            width: 1100px;
            min-width: 1100px;
            @include tablet {
                width: 100%;
                min-width: unset;
                margin: 45px 0;
            }
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .scroll-elements-content {
                width: 150px;
                height: 470px;
                max-height: 470px;
                @include tablet {
                    width: 25vw;
                    height: calc(var(--doc-height) * 0.72);
                }
                @include wide-mobile {
                    width: 15vw;
                    min-width: 120px;
                    height: calc(var(--doc-height) * 0.66);
                }
                @include x-small-mobile {
                    width: 26vw;
                    min-width: 120px;
                }
                border: 1px solid $primary-blue-color;
                border-radius: 5px;
                background-color: $primary-color;
                overflow-y: auto;
                overflow-x: hidden;
                margin-right: 100px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: column;
                @include tablet {
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin: 0;
                    gap: 8px;
                    justify-content: center;
                    padding: 8px 0;
                }
                .scroll-element-box {
                    width: 120px;
                    height: 120px;
                    margin-bottom: 10px;
                    z-index: 10;
                    overflow: visible;
                    @include tablet {
                        width: calc(25vw/2 - 16px);
                        height: 90px;
                        margin: 0;
                    }
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 10px;
                    border: 1px solid rgba($primary-blue-color, 0.5);
                    background-color: $secondary-color;
                    img {
                        height: 60%;
                    }
                    &:first-child {
                        margin-top: 10px !important;
                        @include tablet {
                            margin-top: 0 !important;
                        }
                    }
                }
            }
            .map-content {
                perspective: 190710px;
                position: relative;
                overflow: visible;
                flex-basis: 780px;
                width: 780px;
                min-width: 780px;
                height: 458px;
                min-height: 458px;
                margin-right: 50px;
                @include tablet {
                    flex-basis: unset;
                    min-width: unset;
                    min-height: unset;
                    width: 380px;
                    height: calc(380px * 1.7);
                    margin-right: 85px;
                }
                @include x-small-mobile {
                    // width: 290px;
                }
                .square {
                    position: absolute;
                    top: -52px;
                    width: 556px;
                    height: 556px;
                    @include tablet {
                        width: 338px;
                        height: 340px;
                        margin-right: 0;
                        right: 20px;
                    }
                    @include x-small-mobile {
                        width: 274px;
                        height: 280px;
                    }
                    transform: rotateX(55deg) rotateY(0.9deg) rotateZ(-46deg);
                    transform-origin: center;
                    border-left: 2px solid #3a5313;
                    border-bottom: 2px solid #3a5313;
                    display: flex;
                    flex-wrap: wrap;
                    .box-square {
                        z-index: 4;
                        position: relative;
                        width: calc(69.5px - 1px);
                        height: calc(69.5px - 1px);
                        @include tablet {
                            width: calc(340px / 8 - 1.5px);
                            height: calc(340px / 8 - 1.5px);
                        }
                        @include x-small-mobile {
                            width: calc(280px / 8 - 1.5px);
                            height: calc(280px / 8 - 1.5px);
                        }
                        background-color: transparent;
                        border-right: 0.5px solid #5c8719;
                        border-bottom: 0.5px solid #5c8719;
                        -webkit-transition: 0.3s;
                        transition: 0.3s;
                        img {
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                        &:hover, &.active {
                            @include gradient(90deg, $list-green);
                        }
                    }
                }
                .square {
                    z-index: 10;
                }
                .image-inserts {
                    overflow: visible;
                    img {
                        overflow: visible;
                        position: absolute;
                        &#A2 {
                            z-index: 3;
                            top: 119px;
                            left: 53px;
                            @include tablet {
                                top: 56px;
                                left: -19px;
                            }
                            @include x-small-mobile {
                                top: 34px;
                                left: 52px;
                                width: 180px;
                            }
                        }
                        &#E7 {
                            z-index: 4;
                            top: 89px;
                            right: -6px;
                            @include tablet {
                                top: 34px;
                                right: -45px;
                            }
                            @include x-small-mobile {
                                width: 197px;
                                top: 18px;
                                right: -37px;
                            }
                        }
                        &#A5 {
                            z-index: 8;
                            top: 8px;
                            right: 194px;
                            @include x-small-mobile {
                                top: -2px;
                                right: 164px;
                                width: 94px;
                            }
                        }
                        &#A7 {
                            z-index: 6;
                            top: -11px;
                            right: 294px;
                            @include tablet {
                                top: -25px;
                                left: 127px;
                            }
                            @include x-small-mobile {
                                top: -26px;
                                left: 170px;
                                width: 94px;
                            }
                        }
                        &#D6 {
                            z-index: 7;
                            top: 102px;
                            right: 292px;
                            @include tablet {
                                top: 34px;
                                left: 199px;
                            }
                            @include x-small-mobile {
                                top: 8px;
                                left: 226px;
                            }
                        }
                        &#D4 {
                            z-index: 8;
                            top: 158px;
                            right: 390px;
                            @include tablet {
                                top: 67px;
                                right: 215px;
                            }
                            @include x-small-mobile {
                                top: 38px;
                                right: 174px;
                            }
                        }
                        &#C5 {
                            z-index: 9;
                            top: 31px;
                            right: 406px;
                            width: 55px;
                            @include tablet {
                                top: -52px;
                                right: 196px;
                            }
                            @include x-small-mobile {
                                top: -49px;
                                right: 161px;
                                width: 45px;
                            }
                        }
                        &#D5 {
                            z-index: 9;
                            top: 80px;
                            right: 360px;
                            width: 55px;
                            @include tablet {
                                top: -16px;
                                right: 164px;
                            }
                            @include x-small-mobile {
                                top: -21px;
                                right: 136px;
                                width: 44px;
                            }
                        }
                        &#E5 {
                            z-index: 9;
                            top: 108px;
                            right: 311px;
                            width: 55px;
                            @include tablet {
                                top: 0px;
                                right: 133px;
                            }
                            @include x-small-mobile {
                                top: -5px;
                                right: 112px;
                                width: 44px;
                            }
                        }
                    }
                }
                .numbers,
                .letters {
                    position: absolute;
                    width: 523px;
                    @include tablet {
                        width: calc(340px - 10px);
                    }
                    @include x-small-mobile {
                        width: calc(290px - 10px);
                    }
                    height: 42px;
                    display: flex;
                    justify-content: space-between;
                    transform-style: preserve-3d;
                    .number,
                    .letter {
                        width: 40px;
                        height: 40px;
                        @include tablet {
                            width: calc(340px / 8 - 14px);
                            height: calc(340px / 8 - 14px);
                        }
                        border-radius: 5px;
                        border: 1px solid $primary-blue-color;
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: calc(1rem + 9px);
                        line-height: 28px;
                        -webkit-transition: 0.3s;
                        transition: 0.3s;
                        &.active {
                            background-color: $primary-blue-color;
                            color: #fff;
                        }
                    }
                }
                .numbers {
                    top: 72px;
                    left: -86px;
                    transform: perspective(190710px) rotateX(55deg) rotateY(0.9deg) rotateZ(-46deg);
                    @include tablet {
                        top: 14px;
                        left: -116px;
                    }
                    @include x-small-mobile {
                        top: 1px;
                        left: -33px;
                    }
                }
                .letters {
                    top: 74px;
                    right: -89px;
                    transform: perspective(190710px) rotateX(55deg) rotateY(0.9deg) rotateZ(-46deg) rotate(90deg);
                    @include tablet {
                        top: 16px;
                        right: -110px;
                    }
                    @include x-small-mobile {
                        top: 3px;
                        right: -87px;
                    }
                }
            }
            .footer-options {
                margin-top: 50px;
                width: 100%;
                flex-basis: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

    .info-box {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        border: 1px solid $primary-blue-color;
        border-radius: 5px;
        background-color: $primary-color;
        margin-bottom: 20px;
        img {
            margin-right: 10px;
        }
        span {
            color: #fff;
            text-transform: uppercase;
        }
    }

    .step-solved {
        position: absolute;
        top: calc($directory-modal-content-mobile-padding * -1);
        left: calc($directory-modal-content-mobile-padding * -1);
        #sector-complete {
            position: absolute;
        }
    }

</style>
