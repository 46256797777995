<template>
  <div class="button-container">
    <div class="button-icon">
      <img
        @click="open"
        src="@/assets/header-nav/question-mark.png"
        alt=""
      />
    </div>
    <div v-if="visible" class="button-message uppercase">
        <slot name="button-message"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tooltip-help-button',
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    open: function () {
      this.visible = true;
      setTimeout(() => {
        this.visible = false;
      }, 4000);
    },
    close: function () {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.button-container {
  position: relative;
  .button-icon {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 25px;
    }
  }
  .button-message {
    position: absolute;
    z-index: 2;
    width: 100px;
    padding: 0 10px;
    border-radius: 10px;
    background-color: #050a4a;
    border: 1px solid #323c84;
    color: #fff;
    top: 35px;
    left: -44px;
    span {
      display: block;
      margin-top: 15px;
      color: $primary-blue-color;
    }
    &:after {
      content: ' ';
      z-index: 3;
      position: absolute;
      right: 50px;
      top: -15px;
      border-bottom: 15px solid #050a4a;
      border-right: 15px solid transparent;
      border-left: 15px solid transparent;
      border-top: none;
    }
  }
}
</style>
