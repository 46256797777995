import { render, staticRenderFns } from "./sorian-project.vue?vue&type=template&id=7bca155b&scoped=true&"
import script from "./sorian-project.vue?vue&type=script&lang=js&"
export * from "./sorian-project.vue?vue&type=script&lang=js&"
import style0 from "./sorian-project.vue?vue&type=style&index=0&id=7bca155b&lang=scss&scoped=true&"
import style1 from "./sorian-project.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bca155b",
  null
  
)

export default component.exports