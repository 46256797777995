<template>
    <div class="document">
        <div class="document-content" @click="openCloseModal()">
            <img :src="require(`./assets/file.png`)" alt="">
            <p>{{ this.documentTitle }}</p>
        </div>
        <draggable-modal v-on:close="openCloseModal()" class="document-modal" v-if="this.open">
            <template slot="header">{{ this.documentModalTitle }}</template>
            <template slot="body">
                <div class="document-modal-content">
                    <slot name="modal-content"></slot>
                </div>
            </template>
        </draggable-modal>
    </div>

</template>

<script>
    import DraggableModal from '@/components/modals/draggable-modal';

    export default {
        name: "directory",
        components: {
            DraggableModal,
        },
        props: {
            documentTitle : String,
            documentModalTitle: String
        },
        data() {
            return {
                open: false
            }
        },
        methods: {
            openCloseModal: function () {
                if(this.documentModalTitle) {
                    this.open = !this.open;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/_variables.scss";

    .document {
        width: 100%;
        .document-content {
            text-align: center;
            img {
                width: 40%;
                height: auto;
                margin-bottom: 15px;
                @include tablet {
                    width: 30%;
                    margin-bottom: 7px;
                }
                @include small-mobile {
                    margin: 0;
                }
            }
            p {
                font-size: calc(1rem - 1px);
                line-height: 17px;
                margin: 0;
                color: #fff;
                text-align: center;
                -webkit-transition: 0.3s;
                transition: 0.3s;
            }
        }
        &:hover {
            cursor: pointer;
            .document-content {
                p {
                    color: $primary-blue-color;
                }
            }
        }
    }
</style>
