<template>
    <div>
        <div v-if="$screenSize.isMobileOrSmallTablet.value" class="mobile-modal-title">
            <p>{{ $t('sector3PanelesSolares') }}</p>
        </div>
        <div id="solar-panels">
            <div class="solar-panel-box">
                <span>PF-R50</span>
                <img src="./assets/pf-r50.png" alt="">
            </div>
            <div class="solar-panel-box">
                <span>X-100</span>
                <img src="./assets/x-100.png" alt="">
            </div>
            <div class="solar-panel-box">
                <span>CC-450</span>
                <img src="./assets/cc-450.png" alt="">
            </div>
            <div class="solar-panel-box">
                <span>TS-200</span>
                <img src="./assets/ts-200.png" alt="">
            </div>
            <div class="solar-panel-box">
                <span>PS-150</span>
                <img src="./assets/ps-150.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "solar-panels"
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    #solar-panels {
        padding: 50px;
        width: 850px;
        display: flex;
        @include mobile {
            padding: 0;
            margin-top: 20px;
            width: calc(var(--doc-width) - 80px);
            gap: 10px;
        }
        @include x-small-mobile {
            margin-top: 0;
        }
        justify-content: flex-start;
        flex-wrap: wrap;
        .solar-panel-box {
            flex-basis: calc(33% - 10px);
            width: calc(33% - 10px);
            margin-right: 15px;
            @include mobile {
                margin-right: 0;
                flex-basis: unset;
                width: calc(18%);
            }
            margin-bottom: 17px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            border: 1px solid rgba($primary-blue-color,0.5);
            background-color: rgba($primary-blue-color,0.1);
            -webkit-transition: 0.3s;
            transition: 0.3s;
            &:nth-child(3) {
                margin-right: 0;
            }
            span {
                display: inline-block;
                margin-top: 20px;
                padding: 7px 15px;
                border-radius: 5px;
                margin-bottom: 20px;
                color: $primary-blue-color;
                background-color: $primary-color;
            }
            img {
                width: 120px;
                height: auto;
                margin-bottom: 40px;
                @include mobile {
                    width: 80px;
                    margin-bottom: 20px;
                }
            }
            &:hover {
                cursor: pointer;
                border-color: $primary-blue-color;
            }
        }
    }
</style>
