<template>
    <div class="clues-environment">
        <div class="text-card">
            <p v-html="$t('sector1')"></p>
        </div>
        <div class="clues-container">
            <clues-phase-one v-if="this.getCurrentState().phase.id === 1"></clues-phase-one>
            <clues-phase-two v-if="this.getCurrentState().phase.id === 2"></clues-phase-two>
            <clues-phase-three v-if="this.getCurrentState().phase.id === 3"></clues-phase-three>
            <clues-phase-four v-if="this.getCurrentState().phase.id === 4"></clues-phase-four>
        </div>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex'

    import CluesPhaseOne from './clues/clues-phase-one';
    import CluesPhaseTwo from './clues/clues-phase-two';
    import CluesPhaseThree from './clues/clues-phase-three';
    import CluesPhaseFour from './clues/clues-phase-four';

    export default {
        name: "card-desktop-left",
        components: {
            CluesPhaseOne,
            CluesPhaseTwo,
            CluesPhaseThree,
            CluesPhaseFour
        },
        computed: {
            ...mapGetters(['getCurrentState'])
        }
    }
</script>