<template>
    <div id="calendar">
        <p>{{ $t('sector2CalendarEnero') }}. 2022</p>
        <div class="row-calendar">
            <div class="box-calendar header">{{ $t('sector2Lunes') }}</div>
            <div class="box-calendar header">{{ $t('sector2Martes') }}</div>
            <div class="box-calendar header">{{ $t('sector2Miercoles') }}</div>
            <div class="box-calendar header">{{ $t('sector2Jueves') }}</div>
            <div class="box-calendar header">{{ $t('sector2Viernes') }}</div>
            <div class="box-calendar header">{{ $t('sector2Sabado') }}</div>
            <div class="box-calendar header">{{ $t('sector2Domingo') }}</div>
            <div v-for="number in 5" class="box-calendar disabled">{{ number + 26 }}</div>
            <div v-for="number in 31" class="box-calendar enabled">
                <div v-if="(number === 13)" class="bloqued-box">
                    <img src="./assets/bell.png" alt="">
                </div>
                <div v-if="(number === 13)" class="bloqued-box-message">
                    {{ $t('sector2CalendarEmail') }}
                    <span>jefe.de.operaciones@cecoel.com</span>
                </div>
                {{ number }}
            </div>
            <div v-for="number in 6" class="box-calendar disabled">{{ number }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "calendar"
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    #calendar {
        width: 900px;
        @include tablet {
            width: 100%;
        }
        p {
            font-size: 22px;
            line-height: 24px;
            color: #fff;
            margin-bottom: 25px;
            @include tablet {
                margin: 0;
                font-size: 1.2rem;
                line-height: 20px;
                margin: 0;
            }
        }
        .row-calendar {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .box-calendar {
                position: relative;
                flex-basis: calc(14.28% - 5px - 15px);
                width: calc(14.28% - 5px - 15px);
                height: calc(60px - 15px);
                padding-top: 15px;

                @include tablet {
                    flex-basis: calc(14.28% - 5px - 15px);
                    width: calc(14.28% - 5px - 15px);
                    height: calc(var(--doc-height) * 0.064); 
                }

                @include x-small-mobile {
                    flex-basis: unset;
                    width: calc(14.28% - 5px - 15px);
                    height: calc(var(--doc-height) * 0.054);
                    padding-top: 11px;
                }

                padding-right: 15px;
                margin-bottom: 5px;
                border-radius: 10px;
                color: #fff;
                &.header {
                    background-color: $primary-color;
                    text-align: center;

                    @include tablet {
                        height: 10px;
                        padding-top: 3px;
                        padding-bottom: 9px;
                    }
                }
                &.disabled, &.enabled {
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-start;
                }
                &.disabled {
                    background-color: #505997;
                    color: rgba(255,255,255,0.5);

                }
                &.enabled {
                    background-color: #2e2dbd;
                }
                .bloqued-box {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    width: 40px;
                    height: 40px;
                    @include tablet {
                        top: 7px;
                        height: 28px;
                        width: 28px;
                    }
                    @include x-small-mobile {
                        top: 5px;
                        height: 20px;
                        width: 20px;

                        img {
                            width: 14px;
                        }
                    }

                    border-radius: 8px;
                    background-color: $red-color;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .bloqued-box-message {
                    display: none;
                    position: absolute;
                    z-index: 2;
                    top: -145px;
                    left: 50%;
                    margin-left: -223px;
                    width: 360px;
                    padding: 20px;
                    border-radius: 10px;
                    background-color: #050a4a;
                    border: 1px solid #323c84;
                    text-align: center;
                    color: #fff;
                    @include x-small-mobile {
                        font-size: 14px;
                        padding: 10px;
                        top: -108px;
                        left: 77%;
                    }
                    span {
                        display: block;
                        margin-top: 15px;
                        @include x-small-mobile {
                            margin-top: 8px;
                        }
                        color: $primary-blue-color;
                    }
                    &:after {
                        content: " ";
                        z-index: 3;
                        position: absolute;
                        right: 195px;
                        bottom: -15px;
                        border-top: 15px solid #050a4a;
                        border-right: 15px solid transparent;
                        border-left: 15px solid transparent;
                        border-bottom: none;
                    }
                }
                &:hover {
                    .bloqued-box-message {
                        display: block;
                    }
                }
            }
        }
    }
</style>
