<template>
    <div id="app" @click="time = 90">
        <div class="turn_display">
            <span class="alert_title">Atención</span>
            <span class="text">Coloque su dispositivo en posición horizontal</span>
        </div>
        <div v-if="!getClosedGame()">
            <screen-save v-if="screensaveEnable" :wrongQr="wrongQr"> </screen-save>
            <div v-else>
                <draggable-modal v-if="$screenSize.isMobileOrSmallTablet.value && getIsLoading()">
                    <template slot="body">
                        <div class="for-mobile-force-render"></div>
                    </template>
                </draggable-modal>
                <qwerty v-if="!$screenSize.isMobileOrSmallTablet.value"></qwerty>
                <div v-if="!getPausedGame()">
                    <sector-one v-if="this.getCurrentState().sector.id === 1"></sector-one>
                    <sector-two v-if="this.getCurrentState().sector.id === 2"></sector-two>
                    <sector-three v-if="this.getCurrentState().sector.id === 3"></sector-three>
                    <sector-four v-if="this.getCurrentState().sector.id === 4"></sector-four>
                    <sector-five v-if="this.getCurrentState().sector.id === 5"></sector-five>
                    <sector-final v-if="this.getCurrentState().sector.id === 6"></sector-final>
                </div>
                <div v-if="getPausedGame()">
                    <paused-game></paused-game>
                </div>
            </div>
        </div>
        <div v-else>
            <closed-game></closed-game>
        </div>
    </div>
</template>

<script>
    import appSocket from './app_socket';
    import appWeb from './app_web';

    import ScreenSave from '@/components/screensave/screensave.vue';
    import Qwerty from '@/components/qwerty/qwerty.vue';
    import DraggableModal from '@/components/modals/draggable-modal';

    import SectorOne from '@/components/sectors/sector-one/sector-one';
    import SectorTwo from '@/components/sectors/sector-two/sector-two';
    import SectorThree from '@/components/sectors/sector-three/sector-three';
    import SectorFour from '@/components/sectors/sector-four/sector-four';
    import SectorFive from '@/components/sectors/sector-five/sector-five';

    import SectorFinal from '@/components/sectors/sector-final/sector-final';
    import PausedGame from '@/components/paused-game/paused-game';
    import ClosedGame from '@/components/closed-game/closed-game';

    export default {
        name: "app",
        extends: process.env.VUE_APP_COMMUNICATION_MODE === 'socket' ? appSocket : appWeb,
        components: {
            Qwerty,
            ScreenSave,
            DraggableModal,
            SectorOne,
            SectorTwo,
            SectorThree,
            SectorFour,
            SectorFive,
            SectorFinal,
            PausedGame,
            ClosedGame
        }
    }
</script>

<style lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;500;700&display=swap');
    @import "@/scss/_variables.scss";

    :root{
        @include desktop {
            font-size: 15px;
        }
        @include tablet {
            font-size: 14px;
        }
    }

    html {
        -webkit-text-size-adjust: 100%;
        text-size-adjust: 100%;
        @include desktop {
            font-size: 15px;
        }
        @include tablet {
            background-color: black;
            font-size: 14px;
            margin: 0;
            padding: 0;
            overflow-x: hidden;
            width: var(--doc-width);
            height: var(--doc-height);
            max-width: var(--doc-width);
        }
    }

    #app {
        font-family: 'Inconsolata', monospace;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        // font-size: 16px;
        font-size: 1rem;
        // line-height: 20px;
        line-height: 1.25rem;
    }

    body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        overflow: hidden;

        @include tablet {
            margin: 0;
            padding: 0;
            overflow-x: hidden;
            width: var(--doc-width);
            height: var(--doc-height);
            max-width: var(--doc-width);
        }
    }

    .uppercase {
        text-transform: uppercase;
    }

    .w-full {
        width: 100%;
    }
    
    .loading-layer {
        width: var(--doc-width);
        height: var(--doc-height);
    }

    .sector-content-general {
        margin: 0;

        height: calc(100vh - 80px);
        padding: 40px;
        @include desktop {
            height: calc(var(--doc-height) - 40px);
            padding: 20px;
        }
        @include tablet {
            height: calc(var(--doc-height) - 20px);
            padding: 10px;
        }

        background: url('./assets/background.jpg') no-repeat center center;
        background-size: cover;
        overflow: hidden;
    }

    .container {
        width: 100%;
        max-width: 1800px;
        margin: 0 auto;
    }

    .button-electric {
        font-size: 1rem;

        padding: 15px 25px;
        @include tablet {
            padding: 8px 12px;
        }

        text-transform: uppercase;
        text-align: center;
        border-radius: 10px;
        color: #fff;
        @include gradient(90deg,$list-blue);
        &:hover {
            cursor: pointer;
        }
    }

    * {
        letter-spacing: 1px;
    }

    *::-webkit-scrollbar {
        width: 10px;
    }

    *::-webkit-scrollbar-track {
        border: 1px solid #49519a;
        background-color: #283a8e;
        border-radius: 100px;
    }

    *::-webkit-scrollbar-thumb {
        border-radius: 100px;
        border-left: 0;
        border-right: 0;
        background-color: #97a3dd;
    }

    .turn_display {
        display: none;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.85);
        top: 0;
        left: 0;
        width: var(--doc-width);
        height: var(--doc-height);
        place-content: center;
        z-index: 90000;
    }

    .turn_display span.alert_title {
        font-weight: 900;
        color: #fff;
        text-align: center;
        font-size: 40px;
        margin-bottom: 30px;
    }

    .turn_display span.text {
        font-size: 26px;
        line-height: 28px;
        color: #fff;
        padding: 0 10%;
        text-align: center;
    }

    @media screen and (max-width: 500px) {
        .turn_display {
            display: grid !important;
        }
    }

    @media screen and (max-width: 1200px) and (orientation: portrait) {
        .turn_display {
            display: grid !important;
        }
    }
</style>
