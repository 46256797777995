<template>
    <div id="intro-sector" v-bind:style="{ 'background': 'url(' + require(`${background}`) + ') no-repeat center center' }">
        <div v-if="modalGeneral && getCurrentState().sector.id === 1 && getCurrentState().phase.id === 1" class="modal-intro-general">
            <p class="intro-text">¡{{ $t('hiHeader') }}, <span>{{ getUsername() }}</span>! {{ $t("introSectores") }}</p>
            <div class="button-electric" @click="modalGeneral = !modalGeneral">{{ $t("introSectoresButton") }}</div>
        </div>
        <div v-else class="modal-intro">
            <div class="electra-avatar">
                <img src="./assets/electra-avatar.png" alt="">
            </div>

            <div v-html="$t('introSector')"></div>

            <div class="button-electric" @click="$emit('start-sector')">{{ $t("start") }}</div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: "intro-sector",
        props: {
            description: String,
            sector: String
        },
        computed: {
            ...mapGetters(['getUsername', 'getCurrentState']),
            background: function () {
                return './assets/bg-s' + this.sector + '.jpg';
            }
        },
        data() {
            return {
                modalGeneral: true
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/_variables.scss";

    #intro-sector {
        width: 100%;
        height: 100vh;
        @include tablet {
            height: var(--doc-height);
            width: var(--doc-width);
        }
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: cover !important;
        text-align: center;
        .button-electric {
            display: inline-block;
            margin-top: 40px;
            @include tablet {
                margin-top: 20px;
            }
        }
        .modal-intro-general {
            width: 600px;
            height: auto;
            @include tablet {
                width: calc(var(--doc-width) * 0.8);
                height: 70vh;
            }
            padding: 100px;
            @include tablet {
                padding: 50px;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
            }
            background-color: rgba($primary-color,0.8);
            border-radius: 25px;
            p {
                font-weight: bold;
                text-align: center;
                // font-size: 35px;
                font-size: 2.19rem;
                line-height: 2.32rem;
                    @include tablet {
                        font-size: 1.50rem;
                        line-height: 1.65rem;
                    }
                
                color: #fff;
                span {
                    color: $primary-blue-color;
                }
            }
        }
        .modal-intro {
            width: 600px;
            height: 400px;
            @include tablet {
                width: calc(var(--doc-width) * 0.6);
                height: calc(var(--doc-height) * 0.7);
            }

            padding: 50px;
            @include tablet {
                padding: 25px;
            }

            background-color: rgba($primary-color,0.8);
            border-radius: 25px;
            overflow-y: auto;
            .electra-avatar {
                margin: 0 auto 30px auto;
                width: 95px;
                height: 95px;
                border-radius: 50%;
                animation: voice 1.5s infinite linear;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            div {
                color: #fff;
                text-align: center;
                margin-bottom: 15px;
            }
        }
    }

    @keyframes voice {
        0% {
            -webkit-box-shadow: 0px 1px 30px -4px rgba($primary-blue-color,0.1);
            box-shadow: 0px 1px 30px -4px rgba($primary-blue-color,0.1);
        }
        50% {
            -webkit-box-shadow: 0px 1px 30px -4px rgba($primary-blue-color,1);
            box-shadow: 0px 1px 30px -4px rgba($primary-blue-color,0.9);
        }
        50% {
            -webkit-box-shadow: 0px 1px 30px -4px rgba($primary-blue-color,1);
            box-shadow: 0px 1px 30px -4px rgba($primary-blue-color,0.9);
        }
        100% {
            -webkit-box-shadow: 0px 1px 30px -4px rgba($primary-blue-color,0.01);
            box-shadow: 0px 1px 30px -4px rgba($primary-blue-color,0.01);
        }
    }

</style>
