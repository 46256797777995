<template>
    <div>
        <HowToPlayButton
            v-if="$screenSize.isMobileOrSmallTablet.value && !stepSolved"
            class="how-to-play-button">
                <template slot="modal-content">
                    <div class="text-top-box">
                        <p>{{ $t('sector3SistemaVoltaStep1TwoTitle') }}</p>
                    </div>
                </template>
        </HowToPlayButton>
        <div id="step-two">
            <div
                v-if="!$screenSize.isMobile.value || stepSolved"
                class="text-box-top"
                :class="{'text-box-top-solved': stepSolved}"
            >
                <p v-if="!$screenSize.isMobile.value && !stepSolved">{{ $t('sector3SistemaVoltaStep1TwoTitle') }}</p>
                <div class="solved-text" v-if="stepSolved">
                    <p v-html="$t('sector3SistemaVoltaStep1TwoCorrect')"></p>
                </div>
            </div>
            <div class="boxes">
                <div
                    class="box"
                    :id="box.letter"
                    @click="setBox(box.value,box.letter)"
                    v-bind:style="{ order: (key <= 3) ? 1 : 3 }"
                    v-for="(box,key) in boxes"
                >
                    <span class="letter">{{ box.letter }}</span> 
                    <p class="name" :class="{'becomes-header': stepSolved || $screenSize.isXSmallMobile.value }">{{ box.name }}</p>
                    <p class="subname">{{ box.subname }}</p>
                    <img :src="require(`${box.image}`)" alt="">
                </div>
                <div v-bind:class="{'line-solution' : true, 'incorrect' : (total > 1850), 'correct' : stepSolved}">
                    <div class="box-line">
                        <span>{{ total }}</span>
                    </div>
                    <div class="line">
                        <span v-bind:style="{width: checkWidthBar}"></span>
                    </div>
                    <div class="box-line">
                        <span v-if="stepSolved">1850</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapMutations } from 'vuex'
    import { mapGetters } from 'vuex'

    import HowToPlayButton from '@/components/buttons/how-to-play-button/how-to-play-button';

    export default {
        name: "step-two",
        components: {
            HowToPlayButton
        },
        data() {
            return {
                total: 0,
                stepSolved: false,
                boxes:  [
                    {letter: 'A', image: './assets/steptwo/A.png', name: this.$i18n.t('sector3SistemaVoltaStep1TwoA'), subname: this.$i18n.t('sector3SistemaVoltaStep1TwoAName'), value: 300},
                    {letter: 'B', image: './assets/steptwo/B.png', name: this.$i18n.t('sector3SistemaVoltaStep1TwoB'), subname: this.$i18n.t('sector3SistemaVoltaStep1TwoBName'), value: 150},
                    {letter: 'C', image: './assets/steptwo/C.png', name: this.$i18n.t('sector3SistemaVoltaStep1TwoC'), subname: this.$i18n.t('sector3SistemaVoltaStep1TwoCName'), value: 800},
                    {letter: 'D', image: './assets/steptwo/D.png', name: this.$i18n.t('sector3SistemaVoltaStep1TwoD'), subname: this.$i18n.t('sector3SistemaVoltaStep1TwoDName'), value: 450},
                    {letter: 'E', image: './assets/steptwo/E.png', name: this.$i18n.t('sector3SistemaVoltaStep1TwoE'), subname: this.$i18n.t('sector3SistemaVoltaStep1TwoEName'), value: 120},
                    {letter: 'F', image: './assets/steptwo/F.png', name: this.$i18n.t('sector3SistemaVoltaStep1TwoF'), subname: this.$i18n.t('sector3SistemaVoltaStep1TwoFName'), value: 200},
                    {letter: 'G', image: './assets/steptwo/G.png', name: this.$i18n.t('sector3SistemaVoltaStep1TwoG'), subname: this.$i18n.t('sector3SistemaVoltaStep1TwoGName'), value: 450},
                    {letter: 'H', image: './assets/steptwo/H.png', name: this.$i18n.t('sector3SistemaVoltaStep1TwoH'), subname: this.$i18n.t('sector3SistemaVoltaStep1TwoHName'), value: 300},
                ]
            }
        },
        computed: {
            ...mapGetters(['getCurrentState']),
            checkWidthBar: function () {
                if(this.total >= 1850 ) return '100%';
                else return (this.total/1850)*100+'%';
            }
        },
        created() {
            this.checkPhaseStatus();
        },
        methods: {
            ...mapMutations(['updateCurrentPhase','updateUserdata']),
            checkPhaseStatus : function() {
                let state = this.getCurrentState();
                if(state.phase.id > 2) {
                    this.total = 1850;
                    this.stepSolved = true;
                }
            },
            setBox: function (total, id) {
                if (this.stepSolved) return;

                let element = document.getElementById(id);
                if(element.classList.contains('active')) {
                    this.total -= total;
                }
                else {
                    this.total += total;
                }

                element.classList.toggle('active');

                this.stepSolved = this.total === 1850;
                if(this.stepSolved) {
                    this.updateCurrentPhase({id: 3, complete: false, current: true});
                    this.updateUserdata();
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/_variables.scss";

    .how-to-play-button {
        position: absolute;
        top: 20px;
        left: 20px;
    }

    #step-two {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 1000px;
        min-width: 1000px;
        padding: 40px;
        @include mobile {
            width: calc(var(--doc-width) - 80px);
            min-width: unset;
            padding: 10px;
        }
        .text-box-top {
            flex-basis: 100%;
            width: 100%;
            @include tablet {
                flex-basis: unset; 
                text-align: justify;
                width: calc(var(--doc-width) * 0.80);
            }
            display: flex;
            align-items: center;
            margin-bottom: 50px;
            p {
                color: #fff;
            }
            &.text-box-top-solved {
                margin-bottom: 10px;
                @include x-small-mobile {
                    margin-bottom: 10px;
                }
            }
            .solved-text {
                width: 100%;
                p {
                    text-align: center;
                    margin: 0;
                    &:first-child {
                        color: $green-color;
                        font-size: 30px;
                        line-height: 35px;
                        font-weight: bold;
                        margin-bottom: 5px;
                        @include mobile {
                            font-size: 16px;
                            line-height: 2px;
                        }
                    }
                }
            }
        }
        .boxes {
            flex-basis: 65%;
            width: 65%;
            height: 500px;
            display: flex;
            flex-wrap: wrap;
            @include mobile {
                flex-basis: 100%;
                width: 100%;
                height: auto;
                justify-content: space-between;
            }
            .box {
                position: relative;
                flex-basis: 110px;
                width: 110px;
                margin-right: 25px;
                height: 120px;
                justify-content: flex-end;
                @include mobile {
                    flex-basis: unset;
                    width: 20%;
                    margin-right: 0;
                    height: auto;
                    justify-content: flex-start;
                }
                padding: 10px;
                @include x-small-mobile {
                    padding: 5px 8px;
                }
                border: 2px solid rgba($primary-blue-color,0.5);
                display: flex;
                flex-direction: column;
                border-radius: 10px;
                -webkit-transition: 0.3s;
                transition: 0.3s;
                .letter {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    font-size: 45px;
                    line-height: 48px;
                    color: #6e76a0;
                    @include mobile {
                        position: unset;
                        font-size: 18px;
                        line-height: 18px;
                    }
                    @include x-small-mobile {
                        font-size: 15px;
                        line-height: 15px;
                    }
                }
                img {
                    position: absolute;
                    top: -18px;
                    right: -20px;
                    @include mobile {
                        top: unset;
                        bottom: 4px;
                        right: 4px;
                        width: 50px;
                    }
                    @include x-small-mobile {
                        width: 36px;
                    }
                }
                p {
                    margin: 0;
                    @include mobile {
                        letter-spacing: 0.4px;
                    }
                    &.name {
                        font-size: 1.13rem;
                        line-height: 20px;
                        font-weight: bold;
                        color: $primary-blue-color;
                        margin-bottom: 5px;
                        &.becomes-header {
                            @include mobile {
                                position: absolute;
                                top: 9px;
                                left: 27px;
                            }
                            @include x-small-mobile {
                                font-size: 1rem;
                                top: 3px;
                                left: 20px;
                            }
                        }
                    }
                    &.subname {
                        font-size: 0.87rem;
                        line-height: 16px;
                        min-height: 35px;
                        color: #fff;
                        @include mobile {
                            min-height: unset;
                            width: 50%;
                        }
                        @include x-small-mobile {
                            line-height: 14px;
                        }
                    }
                }
                &:hover,
                &.active {
                    cursor: pointer;
                    border-color: $primary-blue-color;
                    -webkit-box-shadow: 0px 1px 30px -4px rgba($primary-blue-color,0.71);
                    box-shadow: 0px 1px 30px -4px rgba($primary-blue-color,0.71);
                }

                &#A,
                &#H {
                    img {
                        width: 40px;
                        @include mobile {
                            width: 30px;
                        }
                    }
                }
            }
            .line-solution {
                order: 2;
                flex-basis: calc(100% - 50px);
                width: calc(100% - 50px);
                margin: -20px 0 20px 0;
                @include mobile {
                    flex-basis: 100%;
                    width: 100%;
                    margin: 6px 0;
                }
                display: flex;
                align-items: center;
                .box-line {
                    border: 1px solid $primary-blue-color;
                    width: 120px;
                    height: 60px;
                    border-radius: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    span {
                        color: $primary-blue-color;
                        font-size: 25px;
                        line-height: 27px;
                    }
                    @include mobile {
                        width: 64px;
                        height: 22px;
                        span {
                            font-size: 16px;
                            line-height: 16px;
                        }
                    }
                }
                .line {
                    flex-basis: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    height: 10px;
                    border-top: 1px solid $primary-blue-color;
                    border-bottom: 1px solid $primary-blue-color;
                    span {
                        display: inline-block;
                        height: 10px;
                        @include gradient(90deg,$list-green);
                        -webkit-transition: 0.3s;
                        transition: 0.3s;
                    }
                }
                &.incorrect {
                    .box-line {
                        border-color: $red-color;
                        background-color: rgba($red-color,0.5);
                        span {
                            color: #fff;
                        }
                    }
                    .line {
                        border-color: $red-color;
                        span {
                            @include gradient(90deg,$list-red);
                        }
                    }
                }
                &.correct {
                    .box-line {
                        border-color: $green-color;
                        background-color: rgba($green-color,0.5);
                        span {
                            color: #fff;
                        }
                    }
                    .line {
                        border-color: $green-color;
                    }
                }

            }
        }
    }
</style>
