<template>
    <div class="button-container">
        <div @click="openCloseModal()" class="button uppercase">
            <span>{{ $t('sector4ProyectoSorianCoordenadas') }}</span>
        </div>
        <draggable-modal v-on:close="close()" v-if="this.open">
            <template slot="body">
                <div class="help-text">
                    <slot name="modal-content"></slot>
                </div>
            </template>
        </draggable-modal>
    </div>
</template>

<script>
    import DraggableModal from '@/components/modals/draggable-modal';

    export default {
        name: "coordinates-button",
        components: {
            DraggableModal,
        },
        data() {
            return {
                open: false
            }
        },
        methods: {
            openCloseModal: function () {
                console.log('openCloseModal');
                this.open = !this.open;
            },
            close: function () {
                console.log('close');
                this.open = false;
            }
        }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.button-container {
    max-width: 180px;
    z-index: 99;

    .button {
        display: flex;
        justify-content: center;
        background-color: $secondary-color;
        border: none;
        border-radius: 10px;
        padding: 5px 8px;
        font-size: 1.1rem;
        cursor: pointer;
        transition: background-color 0.3s;

        span {
            color: #fff;
            font-size: 1.2rem;
            margin-right: 8px;
        }

        img {
            width: 20px;
            height: 20px;
        }
    }
    .help-text {
        padding: 80px 60px;
        color: #fff;
        font-size: 1.2rem;
        line-height: 1.5;
    }
}
</style>
