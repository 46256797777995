import { mapMutations } from 'vuex'
import { mapGetters } from 'vuex'

import axios from 'axios'

const instance = axios.create({
    withCredentials: false
});

export default {
    data() {
        return {
            wrongQr: false,
            screensaveEnable: true,
            time: 160
        }
    },
    computed: {
        ...mapGetters(['getCurrentState','getPausedGame','getClosedGame','getIsLoading'])
    },
    created() {

        this.checkUrlSocket();
        this.reload();

        /* Disable Text Select */
        document.onselectstart = new Function ("return false");
        if (window.sidebar) {
            document.onmousedown = this.disableSelect();
            document.onclick = this.reEnable();
        }

    },
    methods: {
        ...mapMutations(['updateCurrentPhase','updateUsername','updateQRCode','updateCurrentSector','updateUserdata','updateGeneralState','updateTime']),
        checkUrlSocket: function () {

            let url = window.location.href;

            if(url.includes("sector1")) {
                this.passSectorSocket('sector1','10.10.100.140');
            }
            else if(url.includes("sector2")) {
                this.passSectorSocket('sector2','10.10.100.64');
            }
            else if(url.includes("sector3")) {
                this.passSectorSocket('sector3','10.10.100.104');
            }
            else if(url.includes("sector4")) {
                this.passSectorSocket('sector4','10.10.100.113');
            }
            else if(url.includes("sector5")) {
                this.passSectorSocket('sector5','10.10.100.134');
            }
        },
        passSectorSocket: function (sector, ip) {
            let connection = new WebSocket('ws://10.10.100.12:29776');

            connection.onopen = () => {
                console.log("Connection start");
                console.log("Empezando a escuchar");
            };
            connection.onerror = (error) => {
                console.log('Websocket error ' + error);
            };
            connection.onmessage = (e) => {
                //[1] => ipPantalla, [2] => QR
                console.log('Mensaje Socket:');
                console.log(e.data);
                let params = e.data.split(";");
                if (params[1] == ip) {
                    this.updateQRCode({ qrCode: params[2]});
                    this.getUserData(params[2], sector);
                }
            }
        },
        getUserData: function (qrCode, sector) {
            instance.get('https://conectadosalfuturo.com/endpoints/get_escape.php', {
                crossdomain: true,
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin" : "*",

                },
                params: { qrcode : qrCode }
            }).then((response) => {

                let status = response.data.status;
                let data = response.data.datos;
                let lang = response.data.lang;
                let username = response.data.nombre;

                if(lang == 'eng') {
                    this.$i18n.locale = 'en'
                }

                this.updateUsername({username : username});

                if(status == "error") {
                    this.wrongQr = true;
                    setTimeout(() => {
                        this.wrongQr = false;
                    },3000)
                }
                else if(status == "ok") {
                  if (process.env.VUE_APP_LOCATION_MODE === 'single-location') {
                    this.setCurrentGameState(data);
                  } else {
                    this.handleSectorAction(sector, data);
                  }

                  this.screensaveEnable = false;
                }
            });
    },
    setCurrentGameState: function (data) {
      console.log('single sector', data);
      if (data.length === 0) {
        this.passSectorOne();
      } else {
        let dataJson = JSON.parse(data);
		    this.updateGeneralState(dataJson);

		    console.log(dataJson.sectorOne);

        if (dataJson.sectorOne.sector && dataJson.sectorOne.sector.current) {
          this.updateCurrentSector(dataJson.sectorOne.sector);
          this.updateCurrentPhase(dataJson.sectorOne.phase);
          
          return;
        }
        if (dataJson.sectorTwo.sector && dataJson.sectorTwo.sector.current) {
          this.updateCurrentSector(dataJson.sectorTwo.sector);
          this.updateCurrentPhase(dataJson.sectorTwo.phase);
        
        return;
        }
        if (dataJson.sectorThree.sector && dataJson.sectorThree.sector.current) {
          this.updateCurrentSector(dataJson.sectorThree.sector);
          this.updateCurrentPhase(dataJson.sectorThree.phase);
        
        return;
        }
        if (dataJson.sectorFour.sector && dataJson.sectorFour.sector.current) {
          this.updateCurrentSector(dataJson.sectorFour.sector);
          this.updateCurrentPhase(dataJson.sectorFour.phase);
        
        return;
        }
        if (dataJson.sectorFive.sector && dataJson.sectorFive.sector.current) {
          this.updateCurrentSector(dataJson.sectorFive.sector);
          this.updateCurrentPhase(dataJson.sectorFive.phase);

          return;
        }
      }
    },
    handleSectorAction: function (sector, data) {
      console.log('handling sectors');
      if (sector === 'sector1') {
        if (data.length === 0) {
          this.passSectorOne();
        } else {
          let dataJson = JSON.parse(data);
          this.updateGeneralState(dataJson);
          if (!dataJson.sectorOne.sector) {
            this.passSectorOne();
          } else {
            this.updateCurrentSector(dataJson.sectorOne.sector);
            this.updateCurrentPhase(dataJson.sectorOne.phase);
          }
        }
      }
      if (sector === 'sector2') {
        if (data.length === 0) {
          this.passSectorTwo();
        } else {
          let dataJson = JSON.parse(data);
          this.updateGeneralState(dataJson);

          if (!dataJson.sectorTwo.sector) {
            this.passSectorTwo();
          } else {
            this.updateCurrentSector(dataJson.sectorTwo.sector);
            this.updateCurrentPhase(dataJson.sectorTwo.phase);
          }
        }
      }
      if (sector === 'sector3') {
        if (data.length === 0) {
          this.passSectorThree();
        } else {
          let dataJson = JSON.parse(data);
          this.updateGeneralState(dataJson);

          if (!dataJson.sectorThree.sector) {
            this.passSectorThree();
          } else {
            this.updateCurrentSector(dataJson.sectorThree.sector);
            this.updateCurrentPhase(dataJson.sectorThree.phase);
          }
        }
      }
      if (sector === 'sector4') {
        if (data.length === 0) {
          this.passSectorFour();
        } else {
          let dataJson = JSON.parse(data);
          this.updateGeneralState(dataJson);

          if (!dataJson.sectorFour.sector) {
            this.passSectorFour();
          } else {
            this.updateCurrentSector(dataJson.sectorFour.sector);
            this.updateCurrentPhase(dataJson.sectorFour.phase);
          }
        }
      }
      if (sector === 'sector5') {
        if (data.length === 0) {
          this.passSectorFive();
        } else {
          let dataJson = JSON.parse(data);
          this.updateGeneralState(dataJson);

          if (!dataJson.sectorFive.sector) {
            this.passSectorFive();
          } else {
            this.updateCurrentSector(dataJson.sectorFive.sector);
            this.updateCurrentPhase(dataJson.sectorFive.phase);
          }
        }
      }
    },
    passSectorOne: function () {
            this.updateCurrentSector({id: 1, complete: false, current: true});
            this.updateCurrentPhase({id: 1, complete: false, current: true});
            this.updateUserdata()
        },
        passSectorTwo: function () {
            this.updateCurrentSector({id: 2, complete: false, current: true});
            this.updateCurrentPhase({id: 1, complete: false, current: true});
            this.updateUserdata()
        },
        passSectorThree: function () {
            this.updateCurrentSector({id: 3, complete: false, current: true});
            this.updateCurrentPhase({id: 1, complete: false, current: true});
            this.updateUserdata()
        },
        passSectorFour: function () {
            this.updateCurrentSector({id: 4, complete: false, current: true});
            this.updateCurrentPhase({id: 1, complete: false, current: true});
            this.updateUserdata()
        },
        passSectorFive: function () {
            this.updateCurrentSector({id: 5, complete: false, current: true});
            this.updateCurrentPhase({id: 1, complete: false, current: true});
            this.updateUserdata()
        },
        disableSelect: function(e) {
            return false
        },
        reEnable: function() {
            return true
        },
        reload: function () {
            setTimeout(() => {
                if(this.time <= 0){
                    window.location.href = "http://10.10.100.12/chooser/";
                }
                else this.time--;

                this.reload();
            }, 1000);
        }
    }
}
