<template>
    <div class="directory">
        <div class="directory-content" @click="openCloseModal()">
            <img v-if="this.directoryBlocked" class="blocked-icon" :src="require(`./assets/locked.png`)" alt="">
            <p>{{ this.directoryTitle }}</p>
            <img :src="require(`./assets/${this.directoryImage}`)" alt="">
            <div class="button-electric" v-if="this.directoryBlocked">{{ $t('ingressPass') }}</div>
        </div>
        <draggable-modal v-on:close="openCloseModal()" class="directory-modal" v-if="this.open">
            <template slot="header">{{ this.directoryModalTitle }}</template>
            <template slot="body">
                <div class="directory-modal-content">
                    <slot name="modal-content"></slot>
                </div>
            </template>
        </draggable-modal>
    </div>
</template>

<script>
    import DraggableModal from '@/components/modals/draggable-modal';

    export default {
        name: "directory",
        components: {
            DraggableModal,
        },
        props: {
            directoryTitle : String,
            directoryImage: String,
            directoryModalTitle: String,
            directoryBlocked : Boolean
        },
        data() {
            return {
                open: false
            }
        },
        methods: {
            openCloseModal: function () {
                this.open = !this.open;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/_variables.scss";

    .directory {
        width: calc(100% - 20px);
        @include tablet {
            width: calc(100vw/7);
        }

        height: calc(100% - 20px);
        border-radius: 10px;
        padding: 10px;
        border: 1px solid $primary-purple-color;
        background-color: $secondary-color;
        .directory-content {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            -webkit-transition: 0.3s;
            p {
                text-align: center;
                text-transform: uppercase;
                width: 60%;
                @include tablet {
                    width: 84%;
                    font-size: 0.9rem;
                    line-height: 1rem;
                    letter-spacing: 0.6px;
                }

                margin-bottom: 1.88rem;
                color: #fff;
            }
            img {
                width: 65px;

                @include tablet {
                    width: calc(100vw/22);
                }

                height: auto;
                margin-bottom: 30px;

                &+p+img {
                    @include tablet {
                        margin-bottom: 10px;
                    }
                }
            }
            .button-electric {
                @include tablet {
                    padding: 4px 6px;
                    line-height: 12px;
                    font-size: 0.8rem;
                }
            }
            .blocked-icon {
                position: absolute;
                top: 5px;
                right: 5px;
                width: 30px;
                @include tablet {
                    top: -17px;
                    right: -18px;
                    width: 22px;
                }

                height: auto;
            }
        }
        .directory-modal {
            .directory-modal-content {
                padding: 30px;
                @include tablet {
                    padding: $directory-modal-content-mobile-padding;
                    height: calc(var(--doc-height) - 40px);
                }
            }
        }
        &:hover {
            cursor: pointer;
            border-color: $primary-blue-color;
        }
    }
</style>
