<template>
    <div id="final-sector">
        <div class="final-sector-content">
            <name></name>
        </div>
        <clues-button v-if="$screenSize.isMobileOrSmallTablet.value">
            <template slot="modal-content">
                <desktop-left-one></desktop-left-one>
            </template>
        </clues-button>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    import Name from './../games/name-dragdrop/name-dragdrop'
    import CluesButton from '@/components/buttons/clues-button/clues-button.vue';
    import DesktopLeftOne from './desktop-left-one.vue';

    export default {
        name: "card-desktop-right",
        components: {
            Name,
            CluesButton,
            DesktopLeftOne
        },
        computed: {
            ...mapGetters(['getCurrentState'])
        },
        methods: {

        }
    }
</script>
