<template>
    <div id="desktop">
        <div v-if="!$screenSize.isMobileOrSmallTablet.value"
            class="left-desktop"
        >
            <card id="card-desktop-left">
                <template slot="header-icon">
                    <img src="" alt="">
                </template>
                <template slot="header">
                    <p>Electra</p>
                </template>
                <template slot="body">
                    <slot name="body-left-desktop"> </slot>
                </template>
            </card>
        </div>
        <div class="right-desktop">
            <card id="card-desktop-right">
                <template slot="header">
                    <p>Dr. Volta. PC</p>
                </template>
                <template slot="body">
                    <slot name="body-right-desktop"> </slot>
                </template>
            </card>
        </div>
    </div>
</template>

<script>

    import Card from '@/components/cards/card.vue';

    export default {
        name: "desktop",
        components: {
            Card
        }
    }
</script>

<style lang="scss">
    @import "@/scss/_variables.scss";

    #desktop {
        margin: 50px 0;
        height: calc(100vh - 225px);
        @include tablet-and-desktop {
            margin: 20px 0;
            height: calc(100vh - 115px);
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left-desktop,
        .right-desktop {
            height: 100%;
        }
        
        .left-desktop {
            flex-basis: calc(35% - 15px);
            width: calc(35% - 15px);
            #card-desktop-left {
                .text-card {
                    max-height: 35vh;
                    overflow-y: auto;
                    p {
                        color: #fff;
                    }
                }
                .clues-card {
                    display: flex;
                    justify-content: space-between;
                    .clue-box {
                        margin-top: 50px;
                        flex-basis: calc(33% - 10px);
                        width: calc(33% - 10px);
                        height: 100%;
                    }
                }
            }
        }
        .right-desktop {
            flex-basis: calc(65% - 15px);
            width: calc(65% - 15px);
            @include tablet {
                flex-basis: 100%;
                width: 100%;
            }

            #card-desktop-right {
                .directories {
                    display: flex;
                    flex-wrap: wrap;
                    @include tablet {
                        flex-wrap: nowrap;
                        align-items: center;
                    }
                    .directory-box {
                        flex-basis: calc(25% - 30px);
                        width: calc(25% - 30px);
                        height: 275px;
                        @include tablet {
                            // flex-basis: calc(50% - 30px);
                            // width: calc(50% - 30px);
                            height: 40vh;
                            max-height: 250px;
                        }

                        margin: 0 30px 30px 0;
                        &:nth-child(4n) {
                            margin-right: 0;
                        }
                        @include tablet {
                            margin-right: calc(100vw/105);
                            &:nth-child(4n) {
                                margin-right: calc(100vw/105);
                            }
                        }

                        .directory {
                            @include tablet {
                                outline: none;
                                -webkit-tap-highlight-color: transparent;
                                user-select: none;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
