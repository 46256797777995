<template>
    <div id="ohm-game" :class="{'ohm-game-mobile': $screenSize.isMobileOrSmallTablet.value}">
        <div v-if="!solved" class="intro-ohm-title w-full">
            <p class="intro-ohm">{{ $t('ohmNumberItitle') }}</p>
            <div v-if="!solved && !otherSolved && $screenSize.isMobileOrSmallTablet.value" class="button-electric" @click="calculate()">{{ $t('ohmButton') }} </div>
        </div>
        <div v-if="showMessage !== 3 && !solved" class="ohm-inputs-image">
            <img src="./assets/circuit.png" alt="">
            <div id="intensity" class="input-ohm">
                <p>{{ $t('ohmIntensidad') }}</p>
                <input type="number" v-model="intensity" autocomplete="off">
            </div>
            <div id="endurance" class="input-ohm">
                <p>{{ $t('ohmResistencia') }}</p>
                <input type="number" v-model="endurance" autocomplete="off">
            </div>
            <div id="voltaje" v-bind:class="{'input-ohm' : true, 'correct' : showMessage === 3, 'error': showMessage === 1}">
                <p>{{ $t('ohmVoltaje') }}</p>
                <p v-if="showMessage === 1" class="error-message">
                    {{ $t('ohmInfoError') }}
                </p>
                <input v-if="voltajeInput > 0" id="voltaje-ohm" type="number" v-model="voltajeInput" autocomplete="off">
                <div v-else class="inputs-content">
                    <input @click="checkValueToClean" id="voltajeOne" type="text" v-model="voltajeOne" autocomplete="off">
                    <input @click="checkValueToClean" id="voltajeTwo" type="text" v-model="voltajeTwo" autocomplete="off">
                    <input @click="checkValueToClean" id="voltajeThree" type="text" v-model="voltajeThree" autocomplete="off">
                    <span> V </span>
                </div>
            </div>
        </div>
        <div v-if="!solved && !otherSolved && !$screenSize.isMobileOrSmallTablet.value" class="ohm-button-footer">
            <div  class="button-electric" @click="calculate()">{{ $t('ohmButton') }} </div>
        </div>
        <div v-if="solved" class="circle-solution correct">
            <div class="circle-solution-number">{{ voltaje }}v</div>
            <div class="message-box">
                <p>{{ message }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapMutations } from 'vuex'
    import { mapGetters } from 'vuex'

    export default {
        name: "ohm-game",
        props: {
            solvedOhm : Boolean,
            voltajeOhm: String,
            intensityOhm: String,
            enduranceOhm: String,
        },
        data() {
            return {
                solved: false,
                otherSolved: false,
                voltaje: 500,
                voltajeInput: 0,
                intensity: 250,
                endurance: 2,
                showMessage: 0,
                message: '',
                voltajeOne: 0,
                voltajeTwo: 0,
                voltajeThree: 0,
            }
        },
        computed: {
            ...mapGetters(['getCurrentState'])
        },
        created() {
            if(this.solvedOhm) {
                this.otherSolved = this.solvedOhm;
                this.voltajeInput = this.voltajeOhm;
                this.intensity = this.intensityOhm;
                this.endurance = this.enduranceOhm;
                this.voltaje = 500;
            }
            else this.checkPhaseStatus();
        },
        methods: {
            ...mapMutations(['updateCurrentPhase','updateUserdata']),
            checkPhaseStatus: function () {
                let state = this.getCurrentState();
                this.solved = (state.phase.id > 3) || (state.phase.id === 3 && state.phase.complete);
                if(this.solved) {
                    this.showMessage = 3;
                    this.message = this.$t('ohmInfoCorrect');
                    this.voltaje = 500;
                }
            },
            calculate: function () {
                /* Ley de Ohm: Voltaje = Intensidad*Resistencia (V=I*R) */
                this.voltajeOne = document.getElementById('voltajeOne').value;
                this.voltajeTwo = document.getElementById('voltajeTwo').value;
                this.voltajeThree = document.getElementById('voltajeThree').value;

                let finalVoltaje = this.voltajeOne+this.voltajeTwo+this.voltajeThree;

                if(finalVoltaje != 500) {
                    this.showMessage = 1;
                    setTimeout(() => {
                        this.voltajeInput = 0;
                        this.showMessage = 0;
                    },2500)
                }
                else {
                    this.voltaje = 500;
                    this.showMessage = 3;
                    this.message = this.$t('ohmInfoCorrect');
                    this.solved = true;
                    this.updateCurrentPhase({id: 3, complete: true, current: true});
                    this.updateUserdata();
                }
            },
            checkValueToClean: function (event) {
                if (event.target.value == 0) {
                    event.target.value = '';
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/_variables.scss";

    #ohm-game {
        background-size: cover;
        &.ohm-game-mobile {
            height: var(--doc-height);
            margin-left: calc((100vw - 700px) / 2);
            background-size: contain;

            .button-electric {
                width: fit-content;
            }
        }
        width: 600px;
        min-width: 600px;
        background: url('./assets/ohm-background.png') no-repeat center center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 15px 50px;
        @include small-mobile {
            .intro-ohm-title {
                min-height: 60px;
            }
        }
        .intro-ohm {
            flex-basis: 100%;
            width: 100%;
            color: #fff;
            margin-bottom: 40px;
            @include tablet {
                margin-bottom: 5px;
            }
            @include small-mobile {
                margin-top: 0;
            }
        }
        .ohm-inputs-image {
            flex-basis: 600px;
            width: 600px;
            padding: 80px 0 100px 60px;
            position: relative;

            @include tablet {
                flex-basis: 50%;
                padding: 10% 0 100px 45%;
            }

            @include small-mobile {
                transform: scale(0.9);
                top: -20px;
            }

            @include x-small-mobile {
                transform: scale(0.8);
                top: -60px;
            }

            .input-ohm {
                position: absolute;
                width: 150px;
                p {
                    color: #fff;
                    margin: 0 0 3px 0;
                    &.error-message {
                        margin: 5px 0;
                        font-weight: bold;
                        color: $red-color;
                    }
                }
                input {
                    padding: 15px;
                    width: 75%;
                    @include tablet {
                        width: 50%;
                        padding: 8px;
                    }

                    background-color: transparent;
                    border: 1px solid $green-color;
                    border-radius: 10px;
                    box-shadow: none;
                    outline: none;
                    text-align: center;
                    color: $green-color;
                    text-transform: uppercase;
                    font-size: 1.25rem;
                    line-height: 1.38rem;
                    letter-spacing: 3px;
                    margin: 0;
                }
                &#intensity {
                    bottom: 20px;
                    right: 122px;
                    @include tablet {
                        bottom: 15%;
                        right: 0;
                    }
                }
                &#voltaje {
                    top: 220px;
                    left: -25px;
                    width: 220px;
                    @include tablet {
                        top: 41%;
                        left: 35%;
                        width: 150px;
                    }

                    .inputs-content {
                        display: flex;
                        align-items: center;
                        input {
                            margin-right: 10px;
                        }
                        span {
                            display: inline-block;
                            color: #fff;
                            font-weight: bold;
                            font-size: 25px;
                        }
                    }
                }
                &#endurance {
                    top: -8px;
                    left: 333px;
                    @include tablet {
                        top: -1%;
                        left: 73%;
                    }
                }
                &.error {
                    input {
                        background-color: rgba($red-color, 0.1);
                        border-color: $red-color;
                        color: $red-color;
                    }
                }
            }
            img {
                margin-left: 10%;
                width: 85%;
                height: auto;
            }
            .button-electric {
                margin-top: 15px;
            }
        }
        .ohm-button-footer {
            flex-basis: 100%;
            width: 100%;
            margin-top: 25px;
        }
        .circle-solution {
            padding: 25px 0;
            flex-basis: 100%;
            width: 100%;
            @include mobile {
                width: 80%;
                flex-basis: 80%;
            }
            @include small-mobile {
                width: 90%;
                flex-basis: unset;
            }
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .circle-solution-number {
                width: 200px;
                height: 200px;
                font-size: 50px;
                line-height: 55px;
                @include mobile {
                    width: 130px;
                    height: 130px;
                    font-size: 40px;
                    line-height: 45px;
                }
                @include small-mobile {
                    width: 100px;
                    height: 100px;
                    font-size: 30px;
                    line-height: 35px;
                }
                border-radius: 50%;
                border: 5px solid $primary-blue-color;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                color: #fff;
            }
            .message-box {
                margin-top: 15px;
                padding: 15px;
                border-radius: 15px;
                p {
                    color: #fff;
                    margin: 0;
                    text-align: center;
                }
            }
            &.correct {
                .circle-solution-number {
                    border-color: $green-color;
                    color: #fff;
                    background-color: rgba($green-color, 0.4);
                }
                .message-box p {
                    font-size: calc(1rem + 4px);
                    line-height: calc(1rem + 6px);
                }
            }
        }
    }
</style>
