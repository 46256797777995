<template>
    <div>
        <HowToPlayButton
            v-if="$screenSize.isMobileOrSmallTablet.value && !stepSolved"
            class="how-to-play-button">
                <template slot="modal-content">
                    <div class="text-top-box">
                        <p>
                            {{ $t('sector3SistemaVoltaStep1FourTitle') }}
                        </p>
                    </div>
                </template>
        </HowToPlayButton>
        <div id="step-four">
            <sector-complete v-if="stepSolved" :sectorText="$t('sector3SistemaVoltaStep1FourCorrect')"  v-on:pass-sector="setNextSector"> </sector-complete>
            <div v-else class="step-four-content">
                <div v-if="!$screenSize.isMobileOrSmallTablet.value" class="text-box-top">
                    <p>{{ $t('sector3SistemaVoltaStep1FourTitle') }}</p>
                </div>
                <div class="key-box">
                    <p>{{ $t('sector3SistemaVoltaStep1FourSecuencia') }}</p>
                    <div v-bind:class="{'key-box-input' : true, 'incorrect' : errorKey, 'correct' : stepSolved}">
                        <span>{{ keyOne }}</span>
                        <span>{{ keyTwo }}</span>
                        <span>{{ keyThree }}</span>
                        <span>{{ keyFour }}</span>
                        <span>{{ keyFive }}</span>
                        <span>{{ keySix }}</span>
                    </div>
                </div>
                <div class="qwerty">
                    <div class="numbers">
                        <div class="number" v-bind:style="{backgroundColor: number.color}" @click="clickNumber(number.number)" v-for="number in numbers"></div>
                    </div>
                    <img v-if="$screenSize.isMobile.value" src="./assets/stepfour/qwerty-mobile.png" alt="">
                    <img v-else src="./assets/stepfour/qwerty.png" alt="">
                </div>
            </div>
        </div>
   </div>
</template>

<script>
    import { mapMutations } from 'vuex'
    import { mapGetters } from 'vuex'

    import SectorComplete from  '@/components/sector-complete/sector-complete.vue';
    import HowToPlayButton from '@/components/buttons/how-to-play-button/how-to-play-button';

    export default {
        name: "step-four",
        data() {
            return {
                keyOne: '',
                keyTwo: '',
                keyThree: '',
                keyFour: '',
                keyFive: '',
                keySix: '',
                stepSolved: false,
                errorKey: false,
                numbers: [
                    {number: 1, color: '#bdcf73'},
                    {number: 2, color: '#9d6da5'},
                    {number: 3, color: '#db9600'},
                    {number: 4, color: '#801815'},
                    {number: 5, color: '#009bdb'},
                    {number: 6, color: '#009045'},
                    {number: 7, color: '#87683d'},
                    {number: 8, color: '#db9da1'},
                    {number: 9, color: '#d83a49'},
                    {number: 0, color: '#77bae8'}
                ]
            }
        },
        components: {
            SectorComplete,
            HowToPlayButton
        },
        computed: {
            ...mapGetters(['getCurrentState']),
        },
        methods: {
            ...mapMutations(['updateCurrentPhase','updateCurrentSector','updateUserdata']),
            clickNumber: function (number) {
                if(this.keyOne === '') this.keyOne = number;
                else if(this.keyTwo === '') this.keyTwo = number;
                else if(this.keyThree === '') this.keyThree = number;
                else if(this.keyFour === '') this.keyFour = number;
                else if(this.keyFive === '') this.keyFive = number;
                else if(this.keySix === '') this.keySix = number;
                if(
                    this.keyOne !== ''
                    && this.keyTwo !== ''
                    && this.keyThree !== ''
                    && this.keyFour !== ''
                    && this.keyFive !== ''
                    && this.keySix !== ''
                ) this.checkSolution();
            },
            checkSolution: function () {
                let finalKey = this.keyOne + '' + this.keyTwo + '' + this.keyThree + '' + this.keyFour + '' + this.keyFive + '' + this.keySix;
                if(finalKey === '185546') {
                    this.stepSolved = true;
                }
                else {
                    this.errorKey = true;
                    setTimeout(() => {
                        this.errorKey = false;
                        this.keyOne = '';
                        this.keyTwo = '';
                        this.keyThree = '';
                        this.keyFour = '';
                        this.keyFive = '';
                        this.keySix = '';
                    },2500)
                }
            },
            setNextSector: function () {
                this.updateCurrentSector({id: 3, complete: true, current: false});
                this.updateCurrentSector({id: 4, complete: false, current: true});
                this.updateCurrentPhase({id: 1, complete: false, current: true});
                this.updateUserdata()
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/_variables.scss";

    .how-to-play-button {
        position: absolute;
        top: 20px;
        left: 20px;
    }

    #step-four {
        margin-top: 20px;
        width: 1000px;
        min-width: 1000px;
        @include mobile {
            width: 100%;
            min-width: unset;
        }
        .step-four-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @include mobile {
                flex-direction: row;
                justify-content: space-between;
            }
            .text-box-top {
                flex-basis: 100%;
                width: 100%;
                height: 92px;
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                p {
                    color: #fff;
                }
            }
            .key-box {
                padding: 50px 50px;
                border-radius: 15px;
                background-color: #001861;
                margin-bottom: 30px;
                @include mobile {
                    height: 80%;
                //     display: flex;
                //     align-items: center;
                    margin-bottom: 0;
                    background-color: transparent;
                    padding: 0;
                }
                @include x-small-mobile {
                    padding-right: 20px;
                }
                p {
                    text-transform: uppercase;
                    color: #fff;
                    text-align: center;
                    font-size: 18px;
                    line-height: 20px;
                    @include mobile {
                        font-size: 16px;
                        line-height: 18px;
                        margin-bottom: 10px;
                    }
                    margin: 0;
                }
                .key-box-input {
                    width: 170px;
                    height: 35px;
                    margin: 30px auto 0 auto;
                    @include mobile {
                        margin: auto;
                    }
                    @include x-small-mobile {
                        width: 150px;
                    }
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    span {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: flex-start;
                        width: 21px;
                        height: 35px;
                        font-size: 20px;
                        line-height: 22px;
                        @include mobile {
                            height: 24px;
                            font-size: 18px;
                            line-height: 18px;
                        }
                        font-weight: bold;
                        color: $primary-blue-color;
                        &:after {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            height: 2px;
                            width: 100%;
                            background-color: $primary-blue-color;
                        }
                    }
                    &.incorrect {
                        span {
                            color: $red-color;
                            &:after {
                                background-color: $red-color;
                            }
                        }

                    }
                    &.correct {
                        span {
                            color: $green-color;
                            &:after {
                                background-color: $green-color;
                            }
                        }
                    }
                }
            }
            .qwerty {
                width: 895px;
                min-width: 895px;
                position: relative;
                @include mobile {
                    min-width: unset;
                    width: 560px;
                    img {
                        width: 560px;
                    }
                }
                @include x-small-mobile {
                    width: 506px;
                    img {
                        width: 506px;
                    }
                }
                .numbers {
                    position: absolute;
                    top: 70px;
                    left: 77px;
                    display: flex;
                    @include mobile {
                        top: 33px;
                        left: 32px;
                    }
                    @include x-small-mobile {
                        top: 29px;
                        left: 29px;
                    }
                    .number {
                        width: 47px;
                        height: 47px;
                        border-radius: 5px;
                        margin-right: 7px;
                        -webkit-transition: 0.3s;
                        transition: 0.3s;
                        &:hover {
                            cursor: pointer;
                            transform: scale(0.9);
                        }
                        @include mobile {
                            width: 31px;
                            height: 31.5px;
                            margin-right: 5.8px;
                        }
                        @include x-small-mobile {
                            width: 29px;
                            height: 29px;
                            margin-right: 4.1px;
                        }
                    }
                }
            }
        }
    }
</style>
