import { isRef, watch, onMounted, onBeforeUnmount, unref } from "@vue/composition-api";

export function useEventListener(target, event, handler) {
  if (isRef(target)) {
    watch(target, (value, oldValue) => {
      if (oldValue) {
        oldValue.removeEventListener(event, handler);
      }

      if (value) {
        value.addEventListener(event, handler);
      }
    });
  } else {
      target.addEventListener(event, handler);
  }
}
