<template>
    <div class="directories">
        <div class="directory-box">
            <directory :directoryTitle = "$t('sector3SistemaVolta')"
                       directoryImage = "volta-system.png"
                       :directoryModalTitle = "$t('sector3SistemaVolta')"
            >
                <template slot="modal-content">
                    <steps></steps>
                </template>
            </directory>
        </div>
        <div class="directory-box">
            <directory :directoryTitle = "$t('sector3PanelesSolares')"
                       directoryImage = "folder.png"
                       :directoryModalTitle = "$t('sector3PanelesSolares')"
            >
                <template slot="modal-content">
                    <solar-panels></solar-panels>
                </template>
            </directory>
        </div>
        <clues-button v-if="$screenSize.isMobileOrSmallTablet.value">
            <template slot="modal-content">
                <desktop-left-one></desktop-left-one>
            </template>
        </clues-button>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    import Card from '@/components/cards/card.vue';
    import Directory from '@/components/directory/directory.vue';
    import CluesButton from '@/components/buttons/clues-button/clues-button.vue';
    import DesktopLeftOne from './desktop-left-one.vue';

    import Steps from './../games/steps/steps'
    import SolarPanels from './../games/solar-panels/solar-panels'

    export default {
        name: "card-desktop-right",
        components: {
            Card,
            Directory,
            Steps,
            SolarPanels,
            CluesButton,
            DesktopLeftOne
        },
        computed: {
            ...mapGetters(['getCurrentState'])
        },
        methods: {
            isBlockedDirectory(idDirectory) {
            }
        }
    }
</script>
