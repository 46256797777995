<template>
    <div id="header-nav-username"
    :class="{ 'm-0': $screenSize.isMobileOrSmallTablet.value }">
        <img src="./../../../assets/header-nav/avatar.jpg" alt=""
            :class="{ 'm-0': $screenSize.isMobileOrSmallTablet.value }"
        >
        <span v-if="!$screenSize.isMobileOrSmallTablet.value">{{ getUsername() }}</span>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: "header-nav-username",
        computed: {
            ...mapGetters(['getUsername'])
        }
    }
</script>

<style lang="scss" scoped>
    #header-nav-username {
        display: flex;
        align-items: center;
        padding: 15px;
        @include tablet-and-desktop {
            padding: 10px;
        }

        border-radius: 7px;
        max-width: 150px;
        img {
            width: 50px;
            height: 50px;
            @include desktop {
                margin-left: calc(100vw/140);
                width: calc(100vw/34);
                height: calc(100vw/34);
            }
            @include tablet {
                width: calc(100vw/30.1);
                height: calc(100vw/30.1);
            }

            border-radius: 5px;
            margin-right: 15px;
        }
        span {
            color: #fff;
            text-transform: uppercase;
            word-break: break-all;
        }

        .m-0 {
            margin: 0;
        }
    }
</style>
