<template>
    <div>
        <HowToPlayButton
            v-if="$screenSize.isMobileOrSmallTablet.value"
            class="how-to-play-button">
                <template slot="modal-content">
                    <div class="text-top-box">
                        <p>{{ $t('juegoConectaIntro') }}</p>
                    </div>
                </template>
        </HowToPlayButton>
    <div id="connect-game">
        <p v-if="!connectSolved && !$screenSize.isMobileOrSmallTablet.value" class="intro-game">{{ $t('juegoConectaIntro') }}</p>
        <div v-if="!connectSolved" id="connect-game-unsolved">
            <div id="industry" v-bind:class="{'correct' : totalKwh === 475, 'incorrect' : totalKwh > 475, 'active' : checkFinish}" class="box-connect-game">
                <div class="box-connect-game-content">
                    <img :src="require(`./assets/industry.png`)" alt="">
                    <span class="number-box-connect-game">{{ totalKwh }}<br />kWh</span>
                    <div class="lines-box-connect-game">
                        <div class="line"></div>
                        <div class="line"></div>
                    </div>
                </div>
            </div>

            <div id="eolic-1" class="box-connect-game active">
                <div class="box-connect-game-content">
                    <img :src="require(`./assets/eolic-2.png`)" alt="">
                    <span class="number-box-connect-game">200<br />kWh</span>
                </div>
            </div>

            <div id="eolic-2" class="box-connect-game eolic" @click="setKwh(100,'eolic-2')">
                <div class="box-connect-game-content">
                    <img :src="require(`./assets/eolic.png`)" alt="">
                    <span class="number-box-connect-game">100 <br />kWh</span>
                    <div class="lines-box-connect-game">
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                    </div>
                </div>
            </div>

            <div id="eolic-3" class="box-connect-game eolic" @click="setKwh(30,'eolic-3')">
                <div class="box-connect-game-content">
                    <img :src="require(`./assets/eolic.png`)" alt="">
                    <span class="number-box-connect-game">30<br/>kWh</span>
                    <div class="lines-box-connect-game">
                        <div class="line"></div>
                        <div class="line"></div>
                    </div>
                </div>
            </div>

            <div id="solar-4" class="box-connect-game solar" @click="setKwh(25,'solar-4')">
                <div class="box-connect-game-content">
                    <img :src="require(`./assets/solar.png`)" alt="">
                    <span class="number-box-connect-game">25<br/>kWh</span>
                    <div class="lines-box-connect-game">
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                    </div>
                </div>
            </div>

            <div id="solar-5" class="box-connect-game solar" @click="setKwh(80,'solar-5')">
                <div class="box-connect-game-content">
                    <img :src="require(`./assets/solar.png`)" alt="">
                    <span class="number-box-connect-game">80<br/>kWh</span>
                    <div class="lines-box-connect-game">
                        <div class="line"></div>
                        <div class="line"></div>
                    </div>
                </div>
            </div>

            <div id="eolic-6" class="box-connect-game eolic" @click="setKwh(55,'eolic-6')">
                <div class="box-connect-game-content">
                    <img :src="require(`./assets/eolic.png`)" alt="">
                    <span class="number-box-connect-game">55<br/>kWh</span>
                    <div class="lines-box-connect-game">
                        <div class="line"></div>
                        <div class="line"></div>
                    </div>
                </div>
            </div>

            <div id="solar-7" class="box-connect-game solar" @click="setKwh(35,'solar-7')">
                <div class="box-connect-game-content">
                    <img :src="require(`./assets/solar.png`)" alt="">
                    <span class="number-box-connect-game">35<br/>kWh</span>
                    <div class="lines-box-connect-game">
                        <div class="line"></div>
                    </div>
                </div>
            </div>

            <div id="solar-8" class="box-connect-game solar" @click="setKwh(50,'solar-8')">
                <div class="box-connect-game-content">
                    <img :src="require(`./assets/solar.png`)" alt="">
                    <span class="number-box-connect-game">50<br/>kWh</span>
                    <div class="lines-box-connect-game">
                        <div class="line"></div>
                        <div class="line"></div>
                    </div>
                </div>
            </div>

            <div id="eolic-9" class="box-connect-game eolic" @click="setKwh(75,'eolic-9')">
                <div class="box-connect-game-content">
                    <img :src="require(`./assets/eolic.png`)" alt="">
                    <span class="number-box-connect-game">75<br/>kWh</span>
                    <div class="lines-box-connect-game">
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                    </div>
                </div>
            </div>

            <div id="eolic-10" class="box-connect-game eolic" @click="setKwh(125,'eolic-10')">
                <div class="box-connect-game-content">
                    <img :src="require(`./assets/eolic.png`)" alt="">
                    <span class="number-box-connect-game">125<br/>kWh</span>
                    <div class="lines-box-connect-game">
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                    </div>
                </div>
            </div>

            <div id="eolic-11" class="box-connect-game eolic" @click="setKwh(60,'eolic-11')">
                <div class="box-connect-game-content">
                    <img :src="require(`./assets/eolic.png`)" alt="">
                    <span class="number-box-connect-game">60<br/>kWh</span>
                    <div class="lines-box-connect-game">
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                    </div>
                </div>
            </div>

            <div id="eolic-12" class="box-connect-game eolic" @click="setKwh(100,'eolic-12')">
                <div class="box-connect-game-content">
                    <img :src="require(`./assets/eolic.png`)" alt="">
                    <span class="number-box-connect-game">100<br/>kWh</span>
                    <div class="lines-box-connect-game">
                        <div class="line"></div>
                        <div class="line"></div>
                    </div>
                </div>
            </div>

            <div id="solar-13" class="box-connect-game solar" @click="setKwh(40,'solar-13')">
                <div class="box-connect-game-content">
                    <img :src="require(`./assets/solar.png`)" alt="">
                    <span class="number-box-connect-game">40<br/>kWh</span>
                    <div class="lines-box-connect-game">
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                    </div>
                </div>
            </div>

        </div>
        <div v-else id="connect-game-solved">
            <p class="title">{{ $t('juegoConectaResuelto1') }}</p>
            <p>{{ $t('juegoConectaResuelto2') }}</p>
            <p class="solution">41º24'12"N 2º10'26"E</p>
        </div>
    </div>
</div>
</template>

<script>
    import { mapMutations } from 'vuex';
    import { mapGetters } from 'vuex';

    import HowToPlayButton from '@/components/buttons/how-to-play-button/how-to-play-button';

    export default {
        name: "connect-game",
        components: {
            HowToPlayButton,
        },
        data() {
            return {
                totalKwh: 200,
                checkFinish: false,
                connectSolved: false,
            }
        },
        computed: {
            ...mapGetters(['getCurrentState'])
        },
        created() {
            this.checkPhaseStatus();
        },
        methods: {
            ...mapMutations(['updateCurrentPhase','updateUserdata']),
            checkPhaseStatus: function () {
                let state = this.getCurrentState();
                this.connectSolved = state.sector.id === 1 && state.phase.id === 4 && state.phase.complete;
            },
            setKwh: function (total, id) {

                let element = document.getElementById(id);
                let isFinishConnect = (id === 'eolic-10' || id === 'solar-5');

                if(element.classList.contains('active')) {
                    if(isFinishConnect) this.checkFinish = false;
                    this.totalKwh -= total;
                }
                else {
                    if(isFinishConnect) this.checkFinish = true;
                    this.totalKwh += total;
                }

                element.classList.toggle('active');

                if(this.totalKwh === 475) {
                    setTimeout(() => {
                        this.connectSolved = true;
                        this.updateCurrentPhase({id: 4, complete: true, current: true});
                        this.updateUserdata()
                    },2000);

                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/_variables.scss";

    .how-to-play-button {
        position: absolute;
        top: 20px;
        left: 20px;
    }

    #connect-game {
        padding-top: 15px;
        width: 100%;
        max-width: 1020px;
        min-width: 500px;
        @include tablet {
            max-width: unset;
            min-width: unset;
        }
        .intro-game {
            color: #fff;
            text-align: center;
            margin:  0 25px 20px 25px;
            @include desktop {
                margin-bottom: 0;
                font-size: 18px;
                top: -50px;
            }
        }
        #connect-game-unsolved {
            width: 1020px;
            height: 740px;
            @include tablet {
                width: 100%;
                height: calc(var(--doc-height) - 40px);
            }
            @include desktop {
                transform: scale(0.8);
            }
            position: relative;

            .box-connect-game {
                position: absolute;
                right: 0;
                .box-connect-game-content {
                    position: relative;
                    img {
                        width: 100%;
                        height: auto;
                        position: relative;
                        z-index: 2;
                        @include tablet {
                            width: 85%;
                        }
                        @include standard-mobile {
                            width: 65%;
                        }
                        @include small-mobile {
                            width: 48%;
                        }
                    }
                    .number-box-connect-game {
                        z-index: 1;
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        line-height: 0.8;
                        font-weight: bold;
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        background-color: $primary-color;
                        border: 5px solid $secondary-purple-color;
                        color: #fff;
                        word-break: break-all;
                        -webkit-transition: 0.3s;
                        transition: 0.3s;
                        &:hover {
                            cursor: pointer;
                            background-color: $secondary-blue-color;
                            border-color: $primary-blue-color;
                            &+.lines-box-connect-game {

                                .line {
                                    background-color: $primary-blue-color;

                                }
                            }
                        }
                    }
                    .lines-box-connect-game {
                        @include tablet {
                                        display: none;
                                    }
                        .line {
                            position: absolute;
                            background-color: $secondary-purple-color;
                            -webkit-transition: 0.3s;
                            transition: 0.3s;
                        }
                    }
                }
                &.active {
                    .box-connect-game-content {
                        .number-box-connect-game {
                            background-color: $secondary-blue-color;
                            border-color: $primary-blue-color;
                        }
                        .lines-box-connect-game {
                            .line {
                                background-color: $primary-blue-color;
                            }
                        }
                    }
                }
                &.eolic {
                    width: 60px;
                }
                &.solar {
                    width: 70px;
                }
                &#industry {
                    top: 50px;
                    left: 300px;
                    width: 250px;
                    @include tablet {
                        height: calc(var(--doc-height)* 0.35);
                        width: auto;
                        top: calc(var(--doc-height) / 2 - (var(--doc-height)* 0.35) / 2);
                        right: 20px;
                        left: unset;
                    }
                    @include wide-mobile {
                        height: calc(var(--doc-height)* 0.55);
                        top: calc(var(--doc-height) / 2 - (var(--doc-height)* 0.25) / 2);
                        right: 20px;
                    }
                    img {
                        z-index: 2;
                        position: relative;
                        @include tablet {
                            width: 75%;
                            height: auto;
                            top: -62px;
                            right: -60px;
                        }
                        @include standard-mobile {
                            width: 60%;
                            top: -68px;
                            left: 52px;
                            right: unset;
                        }
                        @include wide-mobile {
                            width: 60%;
                            top: -68px;
                            left: 100px;
                        }
                        @include small-mobile {
                            width: 55%;
                            top: -77px;
                            left: 133px;
                        }
                    }
                    .number-box-connect-game {
                        z-index: 1;
                        top: -10px;
                        right: 0px;
                        width: 100px;
                        height: 100px;
                        @include tablet {
                            top: -70px;
                            width: 80px;
                            height: 80px;
                        }
                        @include wide-mobile {
                            top: -70px;
                            width: 70px;
                            height: 70px;
                        }
                        font-size: 25px;
                    }
                    .lines-box-connect-game {
                        .line:nth-child(1) {
                            top: 38px;
                            right: -65px;
                            width: 65px;
                            height: 2px;
                        }
                        .line:nth-child(2) {
                            top: 40px;
                            right: -65px;
                            width: 2px;
                            height: 153px;
                        }
                    }
                    &.incorrect {
                        .number-box-connect-game {
                            background-color: rgba($red-color,0.5);
                            border-color: $red-color;
                        }
                    }
                    &.correct {
                        .number-box-connect-game {
                            background-color: rgba($green-color,0.5);
                            border-color: $green-color;
                        }
                    }
                }
                &#eolic-1 {
                    top: 40px;
                    left: 20px;
                    width: 180px;
                    @include tablet {
                        height: calc(var(--doc-height) * 0.35);
                        top: calc(var(--doc-height) / 2 - var(--doc-height)* 0.35 / 2);
                    }
                    .number-box-connect-game {
                        top: 40px;
                        right: -50px;
                        width: 60px;
                        height: 60px;
                        @include tablet {
                            top: -20px;
                            right: -30px;
                        }
                        @include standard-mobile {
                            top: -39px;
                            right: 30px;
                        }
                        @include small-mobile {
                            top: -23px;
                            right: 59px;
                        }
                        font-size: 18px;
                    }
                }
                &#eolic-2 {
                    top: 67px;
                    right: 85px;
                    @include tablet {
                        display: none;
                    }
                    .number-box-connect-game {
                        top: 10px;
                        left: -60px;
                    }
                    .lines-box-connect-game {
                        .line:nth-child(1) {
                            top: 40px;
                            right: 120px;
                            width: 85px;
                            height: 2px;
                        }
                        .line:nth-child(2) {
                            top: 40px;
                            right: 204px;
                            width: 2px;
                            height: 135px;
                        }
                        .line:nth-child(3) {
                            top: 175px;
                            right: 204px;
                            width: 85px;
                            height: 2px;
                        }
                    }
                }
                &#eolic-3 {
                    top: 400px;
                    left: 100px;
                    @include tablet {
                        top: 35%;
                        left: 35%;
                    }
                    @include standard-mobile {
                        top: 164px;
                        left: 187px;
                    }
                    @include small-mobile {
                        top: 50px;
                        left: 159px;
                    }
                    .number-box-connect-game {
                        top: -10px;
                        right: -60px;
                        @include tablet {
                            right: -30px;
                        }
                    }
                    .lines-box-connect-game {
                        .line:nth-child(1) {
                            top: -58px;
                            right: -30px;
                            width: 2px;
                            height: 50px;
                        }
                        .line:nth-child(2) {
                            top: -58px;
                            left: 88px;
                            width: 200px;
                            height: 2px;
                        }
                    }
                }
                &#solar-4 {
                    top: 400px;
                    left: 300px;
                    @include tablet {
                        left: 40%;
                        top: 10%;
                    }
                    @include standard-mobile {
                        left: 289px;
                        top: 48px;
                    }
                    .number-box-connect-game {
                        top: -5px;
                        right: -50px;
                        @include standard-mobile {
                            right: -20px;
                        }
                    }
                    .lines-box-connect-game {
                        .line:nth-child(1) {
                            top: -58px;
                            right: -20px;
                            width: 2px;
                            height: 54px;
                        }
                        .line:nth-child(2) {
                            top: -58px;
                            right: -59px;
                            width: 40px;
                            height: 2px;
                        }
                        .line:nth-child(3) {
                            top: -158px;
                            right: -59px;
                            width: 2px;
                            height: 102px;
                        }
                        .line:nth-child(4) {
                            top: -158px;
                            left: 128px;
                            width: 120px;
                            height: 2px;
                        }
                    }
                }
                &#solar-5 {
                    top: 300px;
                    left: 460px;
                    @include tablet {
                        display: none;
                    }
                    .number-box-connect-game {
                        top: -5px;
                        right: -50px;
                    }
                    .lines-box-connect-game {
                        .line:nth-child(1) {
                            top: -58px;
                            right: -20px;
                            width: 2px;
                            height: 54px;
                        }
                        .line:nth-child(2) {
                            top: -58px;
                            right: -83px;
                            width: 64px;
                            height: 2px;
                        }
                    }
                }
                &#eolic-6 {
                    top: 270px;
                    right: 260px;
                    @include tablet {
                        display: none;
                    }
                    .number-box-connect-game {
                        top: -5px;
                        right: -50px;
                    }
                    .lines-box-connect-game {
                        .line:nth-child(1) {
                            top: -28px;
                            right: -20px;
                            width: 2px;
                            height: 27px;
                        }
                        .line:nth-child(2) {
                            top: -28px;
                            right: -20px;
                            width: 49px;
                            height: 2px;
                        }
                    }
                }
                &#solar-7 {
                    top: 220px;
                    right: 20px;
                    @include tablet {
                        display: none;
                    }
                    .number-box-connect-game {
                        top: -5px;
                        left: -50px;
                    }
                    .lines-box-connect-game {
                        .line:nth-child(1) {
                            top: 22px;
                            right: 120px;
                            width: 100px;
                            height: 2px;
                        }
                    }
                }
                &#solar-8 {
                    top: 600px;
                    left: 150px;
                    @include tablet {
                        top: 65%;
                        left: 20%;
                    }
                    @include standard-mobile {
                        top: 298px;
                        left: 115px;
                    }
                    @include small-mobile {
                        top: 156px;
                        left: 140px;
                    }
                    .number-box-connect-game {
                        top: -5px;
                        right: -50px;
                        @include tablet {
                            right: -30px;
                        }
                        @include small-mobile {
                            right: -16px;
                            top: -31px;
                        }
                    }
                    .lines-box-connect-game {
                        .line:nth-child(1) {
                            top: -41px;
                            right: -22px;
                            width: 2px;
                            height: 36px;
                        }
                        .line:nth-child(2) {
                            top: -43px;
                            left: 90px;
                            width: 100px;
                            height: 2px;
                        }
                    }
                }
                &#eolic-9 {
                    top: 600px;
                    left: 420px;
                    @include tablet {
                        top: 80%;
                        left: 45%;
                    }
                    @include standard-mobile {
                        top: 241px;
                        left: 357px;
                    }
                    @include small-mobile {
                        top: calc(var(--doc-height) - 102px);
                        left: 293px;
                    }
                    .number-box-connect-game {
                        top: -5px;
                        left: -50px;
                    }
                    .lines-box-connect-game {
                        .line:nth-child(1) {
                            top: 23px;
                            left: -80px;
                            width: 30px;
                            height: 2px;
                        }
                        .line:nth-child(2) {
                            top: -41px;
                            left: -82px;
                            width: 2px;
                            height: 66px;
                        }
                        .line:nth-child(3) {
                            top: -43px;
                            left: -82px;
                            width: 115px;
                            height: 2px;
                        }
                        .line:nth-child(4) {
                            top: -101px;
                            left: 33px;
                            width: 2px;
                            height: 60px;
                        }
                        .line:nth-child(5) {
                            top: -101px;
                            right: -10px;
                            width: 35px;
                            height: 2px;
                        }
                        .line:nth-child(6) {
                            top: -165px;
                            right: -12px;
                            width: 2px;
                            height: 66px
                        }
                        .line:nth-child(7) {
                            top: -165px;
                            right: -127px;
                            width: 115px;
                            height: 2px;
                        }
                    }
                }
                &#eolic-10 {
                    top: 480px;
                    left: 530px;
                    @include tablet {
                        top: 44%;
                        left: 50%;
                    }
                    @include standard-mobile {
                        top: 132px;
                        left: 384px;
                    }
                    @include standard-mobile {
                        left: 45%;
                    }
                    .number-box-connect-game {
                        top: -5px;
                        right: -50px;
                        @include tablet {
                            right: -30px;
                        }
                    }
                    .lines-box-connect-game {
                        .line:nth-child(1) {
                            top: -45px;
                            left: 77px;
                            width: 2px;
                            height: 40px;
                        }
                        .line:nth-child(2) {
                            top: -45px;
                            left: 79px;
                            width: 35px;
                            height: 2px;
                        }
                        .line:nth-child(3) {
                            top: -236px;
                            left: 114px;
                            width: 2px;
                            height: 193px;
                        }
                        .line:nth-child(4) {
                            top: -238px;
                            left: 83px;
                            width: 33px;
                            height: 2px;
                        }
                    }
                }
                &#eolic-11 {
                    top: 560px;
                    right: 280px;
                    @include tablet {
                        top: 93px;
                        right: 300px;
                    }
                    @include standard-mobile {
                        top: 23px;
                    }
                    @include wide-mobile {
                        right: 30%;
                    }
                    .number-box-connect-game {
                        top: -5px;
                        right: -50px;
                        @include tablet {
                            right: -30px;
                        }
                    }
                    .lines-box-connect-game {
                        .line:nth-child(1) {
                            top: -45px;
                            right: -20px;
                            width: 2px;
                            height: 40px;
                        }
                        .line:nth-child(2) {
                            top: -45px;
                            right: -67px;
                            width: 47px;
                            height: 2px;
                        }
                        .line:nth-child(3) {
                            top: -104px;
                            right: -69px;
                            width: 2px;
                            height: 61px;
                        }
                        .line:nth-child(4) {
                            top: -104px;
                            left: -71px;
                            width: 200px;
                            height: 2px;
                        }
                    }
                }
                &#eolic-12 {
                    top: 590px;
                    right: 80px;
                    @include tablet {
                        top: 76%;
                        right: 15%;
                    }
                    @include standard-mobile {
                        top: 241px;
                        right: 118px;
                    }
                    @include small-mobile {
                        top: calc(var(--doc-height) - 102px);
                    }
                    .number-box-connect-game {
                        top: -5px;
                        right: -50px;
                        @include small-mobile {
                            top: 19px;
                            right: -17px;
                        }
                    }
                    .lines-box-connect-game {
                        .line:nth-child(1) {
                            top: -40px;
                            right: -22px;
                            width: 2px;
                            height: 35px;
                        }
                        .line:nth-child(2) {
                            top: -41px;
                            right: -22px;
                            width: 90px;
                            height: 2px;
                        }
                    }
                }
                &#solar-13 {
                    top: 430px;
                    right: 60px;
                    @include tablet {
                        top: 70%;
                        right: 25%;
                    }
                    @include standard-mobile {
                        top: 279px;
                        right: 25%;
                    }
                    @include small-mobile {
                        top: calc(var(--doc-height) - 92px);
                    }
                    .number-box-connect-game {
                        top: -5px;
                        left: -50px;
                    }
                    .lines-box-connect-game {
                        .line:nth-child(1) {
                            top: 55px;
                            right: 88px;
                            width: 2px;
                            height: 65px;
                        }
                        .line:nth-child(2) {
                            top: 119px;
                            right: 88px;
                            width: 65px;
                            height: 2px;
                        }
                        .line:nth-child(3) {
                            top: 87px;
                            right: 151px;
                            width: 2px;
                            height: 33px;
                        }
                    }
                }
            }
        }
        #connect-game-solved {
            width: 500px;
            padding: 40px;
            @include tablet {
                margin: auto;
            }
            p {
                text-align: center;
                color: #fff;
                text-transform: uppercase;
                &.title, &.solution {
                    color: $green-color;
                }
                &.title {
                    font-size: 30px;
                    line-height: 35px;
                    @include small-mobile {
                        margin: 10px;
                    }
                }
                &.solution {
                    font-size: 18px;
                    line-height: 20px;
                }
            }
        }
    }

    @media (hover: none) {
        #connect-game {
            #connect-game-unsolved {
                .box-connect-game {
                    .box-connect-game-content {
                        .number-box-connect-game {
                            background-color: $primary-color;
                            border: 5px solid $secondary-purple-color;
                        }
                    }
                }
            }
        }
    }

</style>
