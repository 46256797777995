import { mapMutations } from 'vuex'
import { mapGetters } from 'vuex'

import axios from 'axios'

const instance = axios.create({
    withCredentials: false
});

export default {
    data() {
        return {
            wrongQr: false,
            screensaveEnable: false,
            time: 160
        }
    },
    computed: {
        ...mapGetters(['getCurrentState', 'getPausedGame', 'getClosedGame', 'getIsLoading'])
    },
    created() {
        let qrCode = this.getCookie('qrCode');

        if (process.env.VUE_APP_ENV === 'local') {
            let url = window.location.href;
            if (url.includes("qrCode")) {
                const searchParams = new URLSearchParams(window.location.search);
                qrCode = searchParams.get('qrCode');
            } else {
                qrCode = process.env.VUE_APP_QR_CODE;
            }
            document.cookie = `qrCode=${qrCode}`;
            document.cookie = "nameExpo=Lucía";
        }

        if (!qrCode) {
            window.location.href = "https://conectadosalfuturo.com/registro/?step=expo&language=esp";
        }

        this.updateQRCode({ qrCode: qrCode });
        this.checkUrlSocket(qrCode);
        //this.reload();

        /* Disable Text Select */
        document.onselectstart = new Function("return false");
        if (window.sidebar) {
            document.onmousedown = this.disableSelect();
            document.onclick = this.reEnable();
        }
    },
    methods: {
        ...mapMutations([
            'updateCurrentPhase',
            'updateUsername',
            'updateQRCode',
            'updateCurrentSector',
            'updateGeneralState',
            'updateIsSocket',
            'updateUserdata',
            'updateIsLoading'
        ]),
        getCookie: function (name) {
            // Split cookie string and get all individual name=value pairs in an array
            var cookieArr = document.cookie.split(";");

            // Loop through the array elements
            for (var i = 0; i < cookieArr.length; i++) {
                var cookiePair = cookieArr[i].split("=");

                /* Removing whitespace at the beginning of the cookie name
                and compare it with the given string */
                if (name == cookiePair[0].trim()) {
                    // Decode the cookie value and return
                    return decodeURIComponent(cookiePair[1]);
                }
            }
            // Return null if not found
            return null;
        },
        checkUrlSocket: function (qrCode) {
            let username = this.getCookie('nameExpo');
            this.updateUsername({ username: username });
            this.updateIsSocket({ isSocket: false });

            let url = window.location.href;

            if (url.includes("eng")) {
                this.$i18n.locale = 'en'
            }

            if (url.includes("sector")) {
                this.setSectorByURL(url);

                return;
            }

            console.log('PROBANDO', qrCode);

            instance.get(process.env.VUE_APP_API_URL + '/endpoints/get_escape.php', {
                headers: {
                    'Content-Type': 'application/json',
                },
                params: { qrcode: qrCode }
            }).then((response) => {
                let status = response.data.status;
                if (status == "error") {
                    this.wrongQr = true;
                    setTimeout(() => {
                        this.wrongQr = false;
                    }, 3000)

                    return;
                }

                let data = response.data.datos;
                this.setCurrentGameState(data);
            })
        },
        setCurrentGameState: function (data) {
            console.log('single sector', data);
            if (data.length === 0) {
                this.passSectorOne();
            } else {
                let dataJson = JSON.parse(data);
                this.updateGeneralState(dataJson);

                console.log(dataJson.sectorOne);

                if (dataJson.sectorOne.sector && dataJson.sectorOne.sector.current) {
                    this.updateCurrentSector(dataJson.sectorOne.sector);
                    this.updateCurrentPhase(dataJson.sectorOne.phase);

                    return;
                }
                if (dataJson.sectorTwo.sector && dataJson.sectorTwo.sector.current) {
                    this.updateCurrentSector(dataJson.sectorTwo.sector);
                    this.updateCurrentPhase(dataJson.sectorTwo.phase);

                    return;
                }
                if (dataJson.sectorThree.sector && dataJson.sectorThree.sector.current) {
                    this.updateCurrentSector(dataJson.sectorThree.sector);
                    this.updateCurrentPhase(dataJson.sectorThree.phase);

                    return;
                }
                if (dataJson.sectorFour.sector && dataJson.sectorFour.sector.current) {
                    this.updateCurrentSector(dataJson.sectorFour.sector);
                    this.updateCurrentPhase(dataJson.sectorFour.phase);

                    return;
                }
                if (dataJson.sectorFive.sector && dataJson.sectorFive.sector.current) {
                    this.updateCurrentSector(dataJson.sectorFive.sector);
                    this.updateCurrentPhase(dataJson.sectorFive.phase);

                    return;
                }
            }
        },
        passSectorOne: function () {
            this.updateCurrentSector({ id: 1, complete: false, current: true });
            this.updateCurrentPhase({ id: 1, complete: false, current: true });
            this.updateUserdata()
        },
        reload: function () {
            setTimeout(() => {
                if(this.time <= 0) window.location.reload();
                else this.time--;
                this.reload();
            }, 1000);
        },

        setSectorByURL: function (url) {
            if (url.includes("sector1")) {
                this.updateCurrentSector({ id: 1, complete: false, current: true });
                this.updateCurrentPhase({ id: 1, complete: false, current: true });
            }
            else if (url.includes("sector2")) {
                this.updateCurrentSector({ id: 1, complete: true, current: false });

                this.updateCurrentSector({ id: 2, complete: false, current: true });
                this.updateCurrentPhase({ id: 1, complete: false, current: true });
            }
            else if (url.includes("sector3")) {
                this.updateCurrentSector({ id: 1, complete: true, current: false });
                this.updateCurrentSector({ id: 2, complete: true, current: false });

                this.updateCurrentSector({ id: 3, complete: false, current: true });
                this.updateCurrentPhase({ id: 1, complete: false, current: true });
            }
            else if (url.includes("sector4")) {
                this.updateCurrentSector({ id: 1, complete: true, current: false });
                this.updateCurrentSector({ id: 2, complete: true, current: false });
                this.updateCurrentSector({ id: 3, complete: true, current: false });

                this.updateCurrentSector({ id: 4, complete: false, current: true });
                this.updateCurrentPhase({ id: 1, complete: false, current: true });
            }
            else if (url.includes("sector5")) {
                this.updateCurrentSector({ id: 1, complete: true, current: false });
                this.updateCurrentSector({ id: 2, complete: true, current: false });
                this.updateCurrentSector({ id: 3, complete: true, current: false });
                this.updateCurrentSector({ id: 4, complete: true, current: false });

                this.updateCurrentSector({ id: 5, complete: false, current: true });
                this.updateCurrentPhase({ id: 1, complete: false, current: true });
            }
            else {
                this.updateCurrentSector({ id: 1, complete: false, current: true });
                this.updateCurrentPhase({ id: 1, complete: false, current: true });
            }
        }
    }
}
