<template>
    <div id="final-game">
        <div class="text">
            <p v-html="$t('finalEndSector')"> </p>
            <p class="final">{{ $t('finalEndSector2') }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "sector-final"
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/_variables.scss";

    #final-game {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        background: url('./assets/background.jpg') no-repeat center center;
        background-size: cover;
        .text {
           padding: 100px 100px 100px 40%;
            p {
                width: 80%;
                color: #fff;
                font-size: 18px;
                line-height: 22px;
                &.final {
                    font-size: 25px;
                    line-height: 28px;
                    color: $primary-blue-color;
                }
            }

            @include tablet {
                height: calc(var(--doc-height) - 120px);
                overflow-y: scroll;
            }
        }
    }

</style>
