<template>
    <div id="clues-button">
        <div v-if="!open" id="button" @click="open = !open" v-bind:class="{ 'active': open }">
            <img src="./assets/pistas-ico.png" alt="">
        </div>
        <draggable-modal class="" v-on:close="close()" v-if="this.open">
            <template slot="body">
                <div class="clues-modal">
                    <slot name="modal-content"></slot>
                </div>
            </template>
        </draggable-modal>
    </div>
</template>

<script>
import DraggableModal from '@/components/modals/draggable-modal';
export default {
    name: "clues-button",
    components: {
        DraggableModal,
    },
    data() {
        return {
            open: false,
        }
    },
    methods: {
        openCloseModal: function () {
            console.log('openCloseModal');
            this.open = !this.open;
        },
        close: function () {
            console.log('close');
            this.open = false;
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

#clues-button {
    #button {
        z-index: 1000;
        // opacity: 0.8;
        position: fixed;
        bottom: -7px;
        right: -7px;
        // width: 70px;
        // height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10000;

        img {
            // width: 60%;
            height: auto;
        }

        &:hover {
            cursor: pointer;
        }

        &.active {
            opacity: 1;
        }
    }

}
</style>
<style lang="scss">
@import "@/scss/_variables.scss";

.clues-modal {
    padding: 40px 20px;

    .text-card {
        max-height: 50vh;
        overflow-y: auto;

        p {
            color: #fff;
            @include tablet {
                margin: 0 12px 0 0;
            }
        }
    }

    .clues-card {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 30px;
        height: auto;
        padding: 12px;

        .clue-box {
            flex-basis: unset;
            width: 40%;
            height: auto;

            .clue {
                width: 100%;
                height: 100%;
            }

            .clue-content {
                border: unset;
                padding: 20px;
                height: 100%;
                justify-content: space-evenly;

                img {
                    @include mobile {
                        height: 50px;
                    }
                }
            }
        }
    }
}

.clues-environment {
    @include tablet {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: calc(var(--doc-height) - 80px);
        gap: 3%;
    }

    .text-card {
        @include tablet {
            width: 60%;
            max-height: unset;
        }

        @include mobile {
            height: 100%;
        }
    }

    .clues-container {
        @include tablet {
            width: 40%;
        }
    }
}
</style>
