import { computed, ref } from "@vue/composition-api";
import { useMedia } from "./media";

export function useScreenSize() {
  const isXSmallMobile = useMedia("(max-height: 300px)");
  const isSmallMobile = useMedia("(max-height: 375px)");
  const isMobile = useMedia("(max-width: 940px)");
  const isTablet = useMedia("(min-width: 768px) and (max-width: 1200px)");
  const isDesktop = useMedia("(min-width: 1201px) and (max-width: 1440px)");
  const isBigDesktop = useMedia("(min-width: 1441px) and (max-width: 1920px)");

  const isLandscape = useMedia("(orientation: landscape)");
  const isPortrait = useMedia("(orientation: portrait)");

  // const isMobile = useMedia("(min-height: 240px)");
  // const isSmallTablet = useMedia("(min-height: 430px)");
  
  
  // const isLaptop = useMedia("(min-width: 1024px)");
  //const isDesktop = useMedia("(min-width: 1025px) and (min-height: 700px)");
  
  // const isDesktop = useMedia("(min-width:1280px) and (min-height: 700px)");
  // const isMobileGridScreen = useMedia("(max-height:767px)");

  const screenType = computed(() => {
    if (isDesktop.value) {
      return "desktop";
    }
    // if (isLaptop.value) {
    //   return "laptop";
    // }

    // if (isSmallTablet.value) {
    //   return "small-tablet";
    // }

    if (isTablet.value) {
      return "tablet";
    }

    if (isMobile.value) {
      return "mobile";
    }
    
    return "unknown";
  });

  const isMobileOrSmallTablet = computed(
    () => (isTablet.value || isMobile.value) && !isDesktop.value
  );

  const innerWidth = ref(window.innerWidth);
  const innerHeight = ref(window.innerHeight);

  const setDimensions = () => {
    // console.log('INICIO documentHeight');
    // console.log('Alto: ' + window.innerHeight);
    // console.log('Ancho: ' + window.innerWidth);
    const doc = document.documentElement;
    innerWidth.value = window.innerWidth;
    innerHeight.value = window.innerHeight;
    doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
    doc.style.setProperty("--doc-width", `${window.innerWidth}px`);
    // margen = 5.21 * window.innerWidth / 100;
  
    if (process.env.VUE_APP_COMMUNICATION_MODE  === 'web') {
      if (window.matchMedia("(orientation: landscape)").matches && (window.innerHeight > window.innerWidth)) {
        // console.log('landscape wrogn');
        doc.style.setProperty("--doc-height", `${window.innerWidth}px`);
        doc.style.setProperty("--doc-width", `${window.innerHeight}px`);
        // margen = 5.21 * window.innerHeight / 100;
        innerWidth = window.innerHeight;
        innerHeight = window.innerWidth;
      };
  
      if (!(window.matchMedia("(orientation: landscape)").matches) && (window.innerHeight < window.innerWidth)) {
        // console.log('Portrait wrogn');
        doc.style.setProperty("--doc-height", `${window.innerWidth}px`);
        doc.style.setProperty("--doc-width", `${window.innerHeight}px`);
        // margen = 5.21 * window.innerHeight / 100;
        innerWidth = window.innerHeight;
        innerHeight = window.innerWidth;
      };
    }
  };
  
    // Actualizar el alto del documento con retraso para evitar
    // la ejecución antes de que termine de redimensionar la ventana
    let resizeTimeout;
    function delayedUpdateScreenHeight() {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(setDimensions, 200); // 200ms de retraso
    }
    window.addEventListener("resize", delayedUpdateScreenHeight);
  
    setDimensions();

  return {
    isXSmallMobile,
    isSmallMobile,
    isMobile,
    isTablet,
    // isSmallTablet,
    // isLaptop,
    isDesktop,
    isBigDesktop,
    // isMobileGridScreen,
    screenType,
    isMobileOrSmallTablet,
    innerWidth,
    innerHeight
  };
}
