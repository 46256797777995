<template>
    <div id="mail-content">
        <div class="mail-card mail-left-side">
            <div v-if="!$screenSize.isMobileOrSmallTablet.value" class="mail-left-side-element">
                <img v-if="$screenSize.isMobileOrSmallTablet.value" src="./assets/envelope-add.png">
                <p v-if="!$screenSize.isMobileOrSmallTablet.value">{{ $t('nuevoMensaje') }}</p>
                <p v-else class="uppercase">{{ $t('new') }}</p>
            </div>
            <div class="mail-left-side-element">
                <img v-if="$screenSize.isMobileOrSmallTablet.value" src="./assets/envelope-download.png">
                <p>{{ $t('bandejaEntrada') }}</p>
            </div>
            <div class="mail-left-side-element">
                <img v-if="$screenSize.isMobileOrSmallTablet.value" src="./assets/envelope-send.png">
                <p v-if="!$screenSize.isMobileOrSmallTablet.value">{{ $t('mensajesEnviados') }}</p>
                <p v-else class="uppercase">{{ $t('sent') }}</p>
            </div>

            <div class="mail-left-side-element">
                <img v-if="$screenSize.isMobileOrSmallTablet.value" src="./assets/envelope-exclamation.png">
                <p>{{ $t('spam') }}</p>
            </div>

            <div class="mail-left-side-element">
                <img v-if="$screenSize.isMobileOrSmallTablet.value" src="./assets/envelope-bookmark.png">
                <p>{{ $t('borradores') }}</p>
                <span class="number">01</span>
            </div>
        </div>
        <div class="mail-card mail-right-side-element">
            <div class="mail-right-side-element-content">
                <div id="from" class="mail-right-side-mail">
                    {{ $t('deEmail') }} Dr. Ohm
                </div>
                <div id="delete" class="icon-mail">
                    <img src="./assets/delete.png" alt="">
                </div>
                <div id="send" class="icon-mail" @click="sendMail()">
                    <img src="./assets/send.png" alt="">
                </div>
                <div class="mail-right-side-mail">
                    {{ $t('paraEmail') }} Dra. Volta
                </div>
                <div class="mail-right-side-mail">
                    {{ $t('asuntoEmail') }} <span>{{ $t('emailSector1Asunto') }}</span>
                </div>
                <div v-if="this.getCurrentState().phase.id == 4" class="mail-right-side-content">
                    <div v-if="$screenSize.isMobileOrSmallTablet.value" class="button-electric mobile" @click="openFile()">{{ $t('emailSector1Boton') }}</div>
                    <div v-html="$t('emailSector1Cuerpo')"></div>
                    <div v-if="!$screenSize.isMobileOrSmallTablet.value" class="button-electric" @click="openFile()">{{ $t('emailSector1Boton') }}</div>
                </div>
                <div v-else class="mail-right-side-content">
                    <p>{{ $t('emailSector1Bloqueado') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import DraggableModal from '@/components/modals/draggable-modal';
    import { mapGetters } from 'vuex'

    export default {
        name: "mail",
        components: {
            DraggableModal,
        },
        computed: {
            ...mapGetters(['getCurrentState'])
        },
        data() {
            return {
                open: false,
                drafMessage: [
                    {message: 'Lorem Ipsum dolor sit amet', from: 'D: Dr. Ohm', to: 'PARA: Dra. Volta', subject: 'Asunto: CONFIDENCIAL', show: false, open: false}
                ],
                inboxMessage: [
                    {message: '', show: false, open: false}
                ]
            }
        },
        methods: {
            openCloseModal: function () {
                this.open = !this.open;
            },
            openFile : function() {
                this.$emit('openFile');
            }
        }
    }
</script>

<style lang="scss">
    @import "@/scss/_variables.scss";
    @import "@/scss/_mail.scss";
</style>
